import React, { useCallback, useEffect, useState } from "react";
import * as backend from "../../Backend";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ReferralCode, User } from "../../generated/types";
import Table from "../layouts/table";
import { Flex, Input, Space } from "antd";

type UserReferralProgramProps = {
  user: User;
  toast: any;
};

const columns = [
  { title: "Full Name", key: "fullName", dataIndex: "fullName" },
  { title: "Email", key: "email", dataIndex: "email" },
  { title: "Date redeemed", key: "createdAt", dataIndex: "createdAt" },
];

const UserReferralProgram = ({ user, toast }: UserReferralProgramProps) => {
  const [referralProgram, setReferralProgram] = useState<ReferralCode | null>();
  const [editing, setEditing] = useState(false);
  const [code, setCode] = useState("");

  const loadReferralProgram = useCallback(async () => {
    try {
      const response = await backend.user.referralProgram.get(user?.id);
      setReferralProgram(response);
      setCode(response?.code || "");
    } catch (e) {}
  }, [user?.id]);

  const onUpdate = (body: any) =>
    backend.user.referralProgram.update(user?.id, body);

  const onEnable = useCallback(() => {
    const call = referralProgram?.id
      ? onUpdate({ active: true })
      : backend.user.referralProgram.create(user?.id);

    call.then((response) => {
      setReferralProgram(response);
      setCode(response?.code || "");
      toast({ type: "good", message: "Referral program enabled" });
    });
  }, [user?.id, referralProgram?.id]) // eslint-disable-line

  const onDisable = useCallback(() => {
    onUpdate({ active: false }).then((response) => {
      setReferralProgram(response);
      toast({ type: "good", message: "Referral program disabled" });
    });
  }, [user?.id]) // eslint-disable-line

  const onAddRemaining = useCallback(() => {
    backend.user.referralProgram
      .update(user?.id, { remaining: (referralProgram?.remaining || 0) + 1 })
      .then((response) => {
        setReferralProgram(response);
        toast({ type: "good", message: "Referral remaining added" });
      });
  }, [user?.id, referralProgram?.remaining]) // eslint-disable-line

  const onRemoveRemaining = useCallback(() => {
    backend.user.referralProgram
      .update(user?.id, { remaining: (referralProgram?.remaining || 0) - 1 })
      .then((response) => {
        setReferralProgram(response);
        toast({ type: "good", message: "Referral remaining removed" });
      });
  }, [user?.id, referralProgram?.remaining]) // eslint-disable-line

  const onChangeAutoAccept = () =>
    onUpdate({ auto_accept: !referralProgram?.autoAccept }).then((response) => {
      setReferralProgram(response);
      toast({ type: "good", message: "Auto-accept changed" });
    });

  const onEdit = () => setEditing(true);
  const onCancelEdit = () => setEditing(false);
  const onChangeCode = (e: any) => setCode(e.target.value);
  const onSaveCode = () => {
    onUpdate({ code }).then((response) => {
      setReferralProgram(response);
      setEditing(false);
      toast({ type: "good", message: "Code updated" });
    });
  };

  const isEnabled = referralProgram?.active;

  useEffect(() => {
    loadReferralProgram();
  }, [user?.id]); // eslint-disable-line

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <h2 className="subtitle">Referral Program</h2>
        </div>
        <div className="column">
          <div className="buttons is-right">
            <button
              disabled={isEnabled}
              className="button is-primary"
              onClick={onEnable}
            >
              Enable
            </button>
            <button
              disabled={!isEnabled}
              className="button is-danger"
              onClick={onDisable}
            >
              Disable
            </button>
            <button
              disabled={!isEnabled}
              className="button is-warning"
              onClick={onChangeAutoAccept}
            >
              Change Auto-Accept
            </button>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <strong>Code:</strong>
        </div>
        <div className="column">
          {editing ? (
            <Flex gap="4px">
              <Input
                type="text"
                value={code}
                onChange={onChangeCode}
                placeholder="Code"
              />
              <CheckOutlined onClick={onSaveCode} />
              &nbsp;
              <CloseOutlined onClick={onCancelEdit} />
            </Flex>
          ) : (
            <div>
              {referralProgram?.code || "-"}&nbsp;
              {isEnabled && (
                <EditOutlined className="cursor-pointer" onClick={onEdit} />
              )}
            </div>
          )}
        </div>
        <div className="column"></div>
        <div className="column"></div>
      </div>

      <div className="columns">
        <div className="column">
          <strong>Auto-Accept:</strong>
        </div>
        <div className="column">{referralProgram?.autoAccept ? "✓" : ""}</div>
        <div className="column"></div>
        <div className="column"></div>
      </div>

      <div className="columns">
        <div className="column">
          <strong>Referral Remaining:</strong>
        </div>
        <div className="column">{referralProgram?.remaining}</div>
        <div className="column">
          <div className="buttons are-small">
            <button
              className="button is-primary"
              disabled={!isEnabled}
              onClick={onAddRemaining}
            >
              Add
            </button>
            <button
              disabled={!isEnabled || referralProgram?.remaining === 0}
              className="button is-danger"
              onClick={onRemoveRemaining}
            >
              Remove
            </button>
          </div>
        </div>
        <div className="column"></div>
      </div>

      <div className="columns">
        <div className="column">
          <strong>Referrals Redeemed:</strong>
        </div>
      </div>

      <Table
        dataSource={referralProgram?.redemptions}
        columns={columns}
        rowKey="id"
      />
    </div>
  );
};

export default UserReferralProgram;
