import * as React from "react";
import { useEffect, useState } from "react";
import * as backend from "../../../Backend";
import Vimeo from "@u-wave/react-vimeo";
import { formatDateTime, objectToJsonFile } from "../../../Util";
import { Lot, VimeoVideo } from "../../../generated/types";
import LotForm from "../../../Components/LotForm";
import { DataTable } from "../../../Components/DataTable";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import FormModal from "../../../Components/FormModal";
import VimeoPlayerModal from "../../../Components/VimeoPlayerModal";
import MediaForm from "../../../Components/MediaForm";
import moment from "moment-timezone";
import ImageSection from "./ImagesSection";
import Sections from "./Sections";
import ComparablesSections from "./ComparablesSection";
import { useToast } from "../../../providers/notification.provider";
import { Link } from "react-router-dom";
import LotSection from "./LotSection";

export const videoTag = (videoId: string | undefined) => (
  <span className={`tag ${videoId ? "is-info" : "is-danger"}`}>
    {videoId ? `Video: #${videoId}` : "No video"}
  </span>
);

export const typeOptions = [
  { label: "Audio", value: "Media::Audio" },
  { label: "PDF", value: "Media::PDF" },
  { label: "Video", value: "Media::Video" },
];

const LotPage = ({ lotId }: { lotId: string }) => {
  const toast = useToast();
  const [disabled, setDisabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const [showMedias, setShowMedias] = useState(false);
  const [showEditLot, setShowEditLot] = useState(false);

  const [showNewMedia, setShowNewMedia] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<any>(null);
  const [showPreregister, setShowPreregister] = useState(false);

  const [imageToAddThumbnail, setImageToAddThumbnail] = useState<any | null>(
    false,
  );
  const [newThumbnailImage, setNewThumbnailImage] = useState<File | undefined>(
    undefined,
  );
  const [newThumbnailImageWidth, setNewThumbnailImageWidth] = useState<
    string | undefined
  >(undefined);
  const [newThumbnailImageHeight, setNewThumbnailImageHeight] = useState<
    string | undefined
  >(undefined);

  const [showNewFullScreenImage, setShowNewFullScreenImage] = useState(false);
  const [newFullScreenImage, setNewFullScreenImage] = useState<
    File | undefined
  >(undefined);
  const [newFullScreenImageWidth, setNewFullScreenImageWidth] = useState<
    string | undefined
  >(undefined);
  const [newFullScreenImageHeight, setNewFullScreenImageHeight] = useState<
    string | undefined
  >(undefined);

  const [showMasterImage, setShowMasterImage] = useState(false);
  const [newMasterImage, setNewMasterImage] = useState<File | undefined>(
    undefined,
  );
  const [newMasterImageWidth, setNewMasterImageWidth] = useState<
    string | undefined
  >(undefined);
  const [newMasterImageHeight, setNewMasterImageHeight] = useState<
    string | undefined
  >(undefined);

  const [preRegistering, setPreRegistering] = useState(false);

  const [showPublishLot, setShowPublishLot] = useState(false);
  const [showActivateAuction, setShowActivateAuction] = useState(false);
  const [showMoveToPastLots, setShowMoveToPastLots] = useState(false);
  const [canAddToPastLots, setCanAddToPastLots] = useState(false);
  const [lotRsvps, setLotRsvps] = useState<any[]>([]);

  const [lot, setLot] = useState<Lot | null>(null);

  const [showFullScreenVimeo, setShowFullScreenVimeo] = useState(false);
  const [showNewFullScreenVimeo, setShowNewFullScreenVimeo] = useState(false);
  const [showMasterVimeo, setShowMasterVimeo] = useState(false);
  const [showNewMasterVimeo, setShowNewMasterVimeo] = useState(false);
  const [vimeoVideo, setVimeoVideo] = useState<VimeoVideo | null>(null);
  const [newVimeoForImageAt, setNewVimeoForImageAt] = useState(-1);
  const [vimeoDeleteAction, setVimeoDeleteAction] = useState<Array<any>>([
    null,
    null,
  ]);

  const onExportLot = () => {
    objectToJsonFile(
      lot,
      `lot-${lot?.title}-${moment().format("DD-MM-YYYY")}.json`,
    );
  };

  function loadLot() {
    backend.lot
      .get(lotId)
      .then((lot) => {
        setCanAddToPastLots(!!lot.canBeAddedToPastLots);
        return setLot(lot);
      })
      .then(() => setShowEditLot(false))
      .then(() => loadLotRsvp());
  }
  function loadLotRsvp() {
    backend.lot
      .getLotRsvp(lotId)
      .then((rsvps: any[]) => {
        setLotRsvps(rsvps);
      })
      .catch((err) => {
        console.log("loadLotRsvp err", err);
      });
  }

  function preRegisterAccounts() {
    setPreRegistering(true);
    setShowPreregister(false);
    backend.lot
      .massRegistrations(lotId)
      .then(() =>
        toast({
          type: "good",
          message: "Started pre-registering accounts. Please check back later.",
        }),
      )
      .then(() => setPreRegistering(false));
  }

  const updateMixpanel = () => {
    backend.lot
      .update(lotId, {
        configurationAttributes: {
          mixpanable: !lot?.configuration?.mixpanable,
        },
      })
      .then(() => {
        loadLot();
        toast({ type: "good", message: "Mixpanel updated" });
      });
  };

  function updateLotOnApp() {
    setDisabled(true);
    backend.lot
      .updateLotOnApp(lotId)
      .then(() => toast({ type: "good", message: "Update lot on App." }));

    setTimeout(() => {
      setDisabled(false);
    }, 3000);
  }

  function toggleActive() {
    backend.lot
      .updateActive(lotId)({ active: !lot!.active })
      .then(setLot)
      .then(() => setShowPublishLot(false))
      .then(() => toast({ type: "good", message: "Lot updated" }));
  }

  function toggleAuctionLive() {
    backend.lot
      .updateAuctionLive(lotId)({ auctionLive: !lot!.auctionLive })
      .then(setLot)
      .then(() => setShowActivateAuction(false))
      .then(() => toast({ type: "good", message: "Auction status updated" }));
  }

  async function uploadMasterImage() {
    if (!newMasterImage || !newMasterImageWidth || !newMasterImageHeight) {
      return;
    }
    setSaving(true);

    await backend.lot.replaceMasterImage(lotId, newMasterImage, {
      width: newMasterImageWidth,
      height: newMasterImageHeight,
    });

    toast({ type: "good", message: "Master image replaced" });
    setShowMasterImage(false);
    setNewMasterImage(undefined);
    setSaving(false);
    loadLot();
  }

  async function uploadFullScreenImage() {
    if (
      !newFullScreenImage ||
      !newFullScreenImageWidth ||
      !newFullScreenImageHeight
    ) {
      return;
    }

    await backend.lot.replaceFullScreenImage(lotId, newFullScreenImage, {
      width: newFullScreenImageWidth,
      height: newFullScreenImageHeight,
    });

    toast({ type: "good", message: "FullScreen image replaced" });
    setShowNewFullScreenImage(false);
    setNewFullScreenImage(undefined);
    setNewFullScreenImageWidth(undefined);
    setNewFullScreenImageHeight(undefined);
    loadLot();
  }

  async function uploadThumbnailImage() {
    if (
      !newThumbnailImage ||
      !newThumbnailImageWidth ||
      !newThumbnailImageHeight
    ) {
      return;
    }

    await backend.lot.addThumbnailImage(
      lotId,
      imageToAddThumbnail.id,
      newThumbnailImage,
      {
        width: newThumbnailImageWidth,
        height: newThumbnailImageHeight,
      },
    );

    toast({ type: "good", message: "Image added" });
    setImageToAddThumbnail(null);
    setNewThumbnailImage(undefined);
    setNewThumbnailImageWidth("");
    setNewThumbnailImageHeight("");
  }

  function moveToPastLot(soldPrice: string, soldOn: string) {
    backend.pastLot
      .create({ lotId: lot!.id, soldPrice, soldOn })
      .then(() => {
        setShowMoveToPastLots(false);
        setCanAddToPastLots(false);
        toast({
          type: "good",
          message: "This lot has been added to the list of past lots",
        });
      })
      .catch((err) => {
        setShowMoveToPastLots(false);
        setCanAddToPastLots(false);
        toast({
          type: "bad",
          message: "Could not add this lot to the list of past lots",
        });
      });
  }

  function updateFullScreenVimeo(
    vimeoId: string,
    width?: number,
    height?: number,
  ) {
    backend.lot
      .updateFullScreenVimeo(lot!.id, vimeoId, width, height)
      .then((data) => {
        setShowFullScreenVimeo(false);
        setShowNewFullScreenVimeo(false);
        lot!.fullScreenVimeoVideo = data.fullScreenVimeoVideo;
        toast({ type: "good", message: "Vimeo video has been updated" });
      })
      .catch((err) => {
        toast({ type: "bad", message: "Could not update the Vimeo video" });
        console.error(err);
      });
  }

  function updateMasterVimeo(vimeoId: string, width?: number, height?: number) {
    backend.lot
      .updateMasterVimeo(lot!.id, vimeoId, width, height)
      .then((data) => {
        setShowMasterVimeo(false);
        setShowNewMasterVimeo(false);
        lot!.masterVimeoVideo = data.masterVimeoVideo;
        toast({ type: "good", message: "Vimeo video has been updated" });
      })
      .catch((err) => {
        toast({ type: "bad", message: "Could not update the Vimeo video" });
        console.error(err);
      });
  }

  function deleteFullScreenVimeo() {
    backend.lot
      .deleteFullScreenVimeo(lot!.id)
      .then(() => {
        setVimeoDeleteAction([null, null]);
        lot!.fullScreenVimeoVideo = undefined;
        toast({ type: "good", message: "Full Screen vimeo has been deleted" });
      })
      .catch((err) => {
        toast({
          type: "bad",
          message: "Could not delete the Full Screen Vimeo video",
        });
        console.error(err);
      });
  }

  function deleteMasterVimeo() {
    backend.lot
      .deleteMasterVimeo(lot!.id)
      .then(() => {
        setVimeoDeleteAction([null, null]);
        lot!.masterVimeoVideo = undefined;
        toast({ type: "good", message: "Master video vimeo has been deleted" });
      })
      .catch((err) => {
        toast({
          type: "bad",
          message: "Could not delete the Master Vimeo video",
        });
        console.error(err);
      });
  }

  const onAddMedia = (media: any) => {
    console.log("media => media", media);
    setCurrentMedia(media || {});
    setShowNewMedia(true);
  };

  const onDeleteMedia = (media: any) => {
    const body = {
      lot: {
        mediasAttributes: [
          {
            id: media.id,
            _destroy: 1,
          },
        ],
      },
    };

    backend.lot.updateMultipartLot(lotId, body).then(() => {
      setCurrentMedia(null);
      setShowNewMedia(false);
      loadLot();
    });
  };

  const onSubmitMedia = (media: any) => {
    backend.lot
      .updateMultipartLot(lotId, {
        lot: {
          mediasAttributes: [media],
        },
      })
      .then(() => {
        setCurrentMedia(null);
        setShowNewMedia(false);
        loadLot();
      });
  };

  useEffect(() => {
    loadLot();
  }, []) // eslint-disable-line

  const onGoBack = () => {
    window.history.back();
  };

  const Nav = () => (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <span className="as_link as_link_padding" onClick={onGoBack}>
            Lots
          </span>
        </li>
        <li className="is-active">
          <span className="as_link_padding">{lotId}</span>
        </li>
      </ul>
    </nav>
  );

  if (!lot) {
    return (
      <>
        <Nav />
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      </>
    );
  }

  if (showEditLot) {
    return (
      <>
        <Nav />
        <LotForm
          lot={lot}
          onSuccess={(_) => loadLot()}
          onCancel={() => setShowEditLot(false)}
        />
      </>
    );
  }

  return (
    <>
      <ConfirmationModal
        bodyText={`Are you sure you want to ${lot.active ? "UNPUBLISH" : "PUBLISH"} lot?`}
        confirmText={lot.active ? "Unpublish" : "Publish"}
        visible={showPublishLot}
        onConfirm={() => toggleActive()}
        onCancel={() => setShowPublishLot(false)}
      />
      <ConfirmationModal
        bodyText={`Are you sure you want to ${lot.auctionLive ? "DEACTIVATE" : "ACTIVATE"} auction?`}
        confirmText={lot.auctionLive ? "Deactivate" : "Activate"}
        visible={showActivateAuction}
        onConfirm={() => toggleAuctionLive()}
        onCancel={() => setShowActivateAuction(false)}
      />
      <ConfirmationModal
        bodyText={`Are you sure you want to register accounts for this auction? Please ensure the AM id is correct! AM is ${lot.auctionMobilityId}`}
        confirmText={"Pre-Register Accounts"}
        visible={showPreregister}
        onConfirm={() => preRegisterAccounts()}
        onCancel={() => setShowPreregister(false)}
      />
      <FormModal
        form={[
          { label: "Enter sold price", required: true },
          {
            label: "Enter date at when the lot was sold",
            inputType: "date",
            required: true,
          },
        ]}
        visible={showMoveToPastLots}
        confirmText={"Move to past lots"}
        onConfirm={moveToPastLot}
        onCancel={() => setShowMoveToPastLots(false)}
      />
      <FormModal
        form={[
          { label: "Vimeo ID", inputType: "text", required: true },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={
          showNewFullScreenVimeo ||
          showNewMasterVimeo ||
          newVimeoForImageAt > -1
        }
        confirmText="Save"
        onCancel={() => {
          setShowNewFullScreenVimeo(false);
          setShowNewMasterVimeo(false);
          setNewVimeoForImageAt(-1);
        }}
        onConfirm={(vimeoId, width, height) => {
          if (showFullScreenVimeo) {
            updateFullScreenVimeo(vimeoId, +width, +height);
          } else if (showMasterVimeo) {
            updateMasterVimeo(vimeoId, +width, +height);
          }
        }}
      />

      <VimeoPlayerModal
        video={vimeoVideo?.id || ""}
        width={vimeoVideo?.width}
        height={vimeoVideo?.height}
        autoplay={true}
        loop={true}
        onCancel={() => setVimeoVideo(null)}
        toast={toast}
        visible={!!vimeoVideo}
      />
      <ConfirmationModal
        bodyText={"Are you sure you want to delete the associated Vimeo video?"}
        confirmText={"Delete"}
        visible={vimeoDeleteAction[0] !== null}
        onConfirm={() => vimeoDeleteAction[0](vimeoDeleteAction[1])}
        onCancel={() => setVimeoDeleteAction([null, null])}
      />
      <div className={`modal ${showMasterImage ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowMasterImage(false)}
        />
        <div className="modal-content">
          <div className="box">
            <div>Upload new master image (png/jpg)</div>
            <div>
              <input
                type="file"
                onChange={(e) => setNewMasterImage(e.target.files?.[0])}
              />
            </div>
            <div>
              <label>
                Width:
                <input
                  type="number"
                  value={newMasterImageWidth}
                  onChange={(e) => setNewMasterImageWidth(e.target.value)}
                />
              </label>
              <label>
                Height:
                <input
                  type="number"
                  value={newMasterImageHeight}
                  onChange={(e) => setNewMasterImageHeight(e.target.value)}
                />
              </label>
            </div>
            {saving ? (
              <div style={{ marginTop: "20px" }}>
                <progress className="progress is-small is-primary" max="100">
                  40%
                </progress>
              </div>
            ) : (
              <div className="buttons is-right">
                <button
                  className="button is-primary"
                  onClick={() => uploadMasterImage()}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => setShowMasterImage(false)}
        />
      </div>

      {showNewMedia && (
        <div className={`modal ${showNewMedia ? "is-active" : ""}`}>
          <div
            className="modal-background"
            onClick={() => setShowNewMedia(false)}
          />
          <div className="modal-content">
            <div className="box">
              <MediaForm media={currentMedia} onSubmit={onSubmitMedia} />
            </div>
          </div>
        </div>
      )}
      <div className={`modal ${showNewFullScreenImage ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setShowNewFullScreenImage(false)}
        />
        <div className="modal-content">
          <div className="box">
            <div>Upload new master image (png/jpg)</div>
            <div>
              <input
                type="file"
                onChange={(e) => setNewFullScreenImage(e.target.files?.[0])}
              />
            </div>
            <div>
              <label>
                Width:
                <input
                  type="number"
                  value={newFullScreenImageWidth}
                  onChange={(e) => setNewFullScreenImageWidth(e.target.value)}
                />
              </label>
              <label>
                Height:
                <input
                  type="number"
                  value={newFullScreenImageHeight}
                  onChange={(e) => setNewFullScreenImageHeight(e.target.value)}
                />
              </label>
            </div>
            <div className="buttons is-right">
              <button
                className="button is-primary"
                onClick={() => uploadFullScreenImage()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => setShowNewFullScreenImage(false)}
        />
      </div>

      <div className={`modal ${imageToAddThumbnail ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setImageToAddThumbnail(null)}
        />
        <div className="modal-content">
          {imageToAddThumbnail?.thumbnail?.url ? (
            <div className="box">
              Current Thumbnail
              <div>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img alt="" src={imageToAddThumbnail.thumbnail.url} />
              </div>
              <div>
                {imageToAddThumbnail.thumbnail.width} x{" "}
                {imageToAddThumbnail.thumbnail.height}
              </div>
            </div>
          ) : null}
          <div className="box">
            <div>Upload new thumbnail image (png/jpg)</div>
            <div>
              <input
                type="file"
                onChange={(e) => setNewThumbnailImage(e.target.files?.[0])}
              />
            </div>
            <div>
              <label>
                Width:
                <input
                  type="number"
                  value={newThumbnailImageWidth}
                  onChange={(e) => setNewThumbnailImageWidth(e.target.value)}
                />
              </label>
            </div>

            <div>
              <label>
                Height:
                <input
                  type="number"
                  value={newThumbnailImageHeight}
                  onChange={(e) => setNewThumbnailImageHeight(e.target.value)}
                />
              </label>
            </div>
            <div className="buttons is-right">
              <button
                className="button is-primary"
                onClick={() => uploadThumbnailImage()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => setImageToAddThumbnail(null)}
        />
      </div>

      <div className="columns is-vcentered">
        <div className="column">
          <h1 className="title">{lot.title}</h1>
        </div>
        <div className="column">
          <div className="buttons is-pulled-right">
            <button
              className="button is-danger is-light"
              onClick={() => setShowPublishLot(true)}
            >
              {lot.active ? "Unpublish" : "Publish"}
            </button>
            <button
              className="button is-danger is-light"
              onClick={() => setShowActivateAuction(true)}
            >
              {lot.auctionLive ? "Deactivate Auction" : "Activate Auction"}
            </button>
            {canAddToPastLots && (
              <button
                className="button is-danger is-light"
                onClick={() => setShowMoveToPastLots(true)}
              >
                Move to Past Lots
              </button>
            )}
          </div>
          <div className="buttons is-pulled-right">
            <button
              className="button is-primary is-light"
              disabled={disabled}
              onClick={() => updateLotOnApp()}
            >
              Update lot on App
            </button>
            <button
              className="button is-primary is-light"
              onClick={onExportLot}
            >
              Export Lot
            </button>
            <button
              className="button is-primary is-light"
              onClick={() => updateMixpanel()}
            >
              {lot.configuration?.mixpanable
                ? "Disable Mixpanel"
                : "Enable Mixpanel"}
            </button>
          </div>
          <div className="buttons is-pulled-right">
            <button
              className="button is-primary is-light"
              onClick={() => setShowEditLot(true)}
            >
              Edit
            </button>
            <button
              className="button is-primary is-light"
              onClick={() => setShowMasterImage(true)}
            >
              Change Master Image
            </button>
          </div>
        </div>
      </div>

      <div className="box">
        <LotSection lot={lot} loadLot={loadLot} />
        <h2>
          Medias{" "}
          <span className="as_link" onClick={() => setShowMedias(!showMedias)}>
            {showMedias ? "Hide" : "Show"}
          </span>
        </h2>
        {showMedias && (
          <div className="box">
            {lot.medias &&
              lot.medias.map((media) => (
                <div key={media.id}>
                  <div className="columns">
                    <div className="column">
                      <strong>Type</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">{media.type || "-"}</div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Title</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">{media.title || "-"}</div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Description</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">
                        {media.description || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Url</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">{media.url || "-"}</div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Thumbnail Url</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">
                        {media.thumbnailUrl || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>File</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">{media.fileUrl || "-"}</div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <strong>Configuration</strong>
                    </div>
                    <div className="column is-three-quarters">
                      <div className="is-linewrap">
                        {media.configuration || "-"}
                      </div>
                    </div>
                  </div>

                  <div className="buttons is-right">
                    <button
                      className="button is-warning"
                      onClick={() => onAddMedia(media)}
                    >
                      Edit
                    </button>
                    <button
                      className="button is-danger"
                      onClick={() => onDeleteMedia(media)}
                    >
                      Delete
                    </button>
                  </div>
                  <hr />
                </div>
              ))}

            <div className="buttons is-right">
              <button className="button" onClick={() => onAddMedia(null)}>
                Add
              </button>
            </div>
          </div>
        )}
        <hr />
        <Sections lotId={lotId} toast={toast} />

        <hr />
        <h2>
          Full Screen Vimeo Video{" "}
          <span
            className="as_link"
            onClick={() => setShowFullScreenVimeo(!showFullScreenVimeo)}
          >
            {showFullScreenVimeo ? "Hide" : "Show"}{" "}
          </span>
          {videoTag(lot.fullScreenVimeoVideo?.id)}
        </h2>

        {showFullScreenVimeo && (
          <div className="box">
            <div className="columns is-multiline">
              {lot.fullScreenVimeoVideo && (
                <div className="box">
                  <Vimeo
                    video={lot.fullScreenVimeoVideo.id}
                    onError={() =>
                      toast({
                        type: "bad",
                        message: `No video found with id ${lot?.fullScreenVimeoVideo?.id} found`,
                      })
                    }
                    loop={true}
                    autoplay={true}
                  />
                  <span>
                    Dimensions: {lot.fullScreenVimeoVideo.width} x{" "}
                    {lot.fullScreenVimeoVideo.height}
                  </span>
                </div>
              )}
            </div>
            <div className="buttons is-right">
              <button
                className="button"
                onClick={() => setShowNewFullScreenVimeo(true)}
              >
                Change
              </button>
              {lot.fullScreenVimeoVideo && (
                <button
                  className="button is-danger is-light"
                  onClick={() =>
                    setVimeoDeleteAction([deleteFullScreenVimeo, null])
                  }
                >
                  Delete Vimeo
                </button>
              )}
            </div>
          </div>
        )}
        <hr />

        <h2>
          Master Vimeo Video{" "}
          <span
            className="as_link"
            onClick={() => setShowMasterVimeo(!showMasterVimeo)}
          >
            {showMasterVimeo ? "Hide" : "Show"}{" "}
          </span>
          {videoTag(lot.masterVimeoVideo?.id)}
        </h2>
        {showMasterVimeo && (
          <div className="box">
            <div className="columns is-multiline">
              {lot.masterVimeoVideo && (
                <div className="box">
                  <Vimeo
                    video={lot.masterVimeoVideo.id}
                    onError={() =>
                      toast({
                        type: "bad",
                        message: `No video found with id ${lot.masterVimeoVideo?.id} found`,
                      })
                    }
                    loop={true}
                    autoplay={true}
                  />
                  <span>
                    Dimensions: {lot.masterVimeoVideo.width} x{" "}
                    {lot.masterVimeoVideo.height}
                  </span>
                </div>
              )}
            </div>
            <div className="buttons is-right">
              <button
                className="button"
                onClick={() => setShowNewMasterVimeo(true)}
              >
                Change
              </button>
              {lot.masterVimeoVideo && (
                <button
                  className="button is-danger is-light"
                  onClick={() =>
                    setVimeoDeleteAction([deleteMasterVimeo, null])
                  }
                >
                  Delete Vimeo
                </button>
              )}
            </div>
          </div>
        )}
        <hr />

        <ImageSection
          lot={lot}
          setVimeoDeleteAction={setVimeoDeleteAction}
          setVimeoVideo={setVimeoVideo}
        />

        <hr />

        <ComparablesSections
          lot={lot}
          setVimeoDeleteAction={setVimeoDeleteAction}
          setVimeoVideo={setVimeoVideo}
        />
      </div>

      <div className="box">
        <div className="columns is-vcentered">
          <div className="column">
            <h2 className="subtitle">Users requested a paddle</h2>
          </div>
        </div>
        <div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Email</th>
                <th>Fullname</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {lotRsvps.map((rsvp: any, index: number) => (
                <tr key={`${index}-${rsvp.id}`}>
                  <td>{rsvp.email}</td>
                  <td>
                    {rsvp.firstName} {rsvp.lastName}
                  </td>
                  <th>
                    <Link to={`/users/${rsvp.id}`} className="as_link">
                      View
                    </Link>
                  </th>
                </tr>
              ))}
              {lotRsvps.length === 0 && (
                <tr>
                  <td>There is nothing here.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="box">
        <div className="columns is-vcentered">
          <div className="column">
            <h2 className="subtitle">Auction Registrations</h2>
          </div>
          <div className="column">
            <div className="buttons is-pulled-right">
              <button
                disabled={preRegistering}
                onClick={() => setShowPreregister(true)}
                className="button is-warning is-light"
              >
                Pre-Register Bidding Accounts
              </button>
            </div>
          </div>
        </div>
        <DataTable
          columns={[
            ["auctionMobilityId", "Registration Auction Mobility ID"],
            [
              "accountName",
              "Account",
              ({ entity }: any) => entity?.account?.name,
            ],
            ["paddle", "Paddle"],
            [
              "auctionMobilityEmail",
              "Account Auction Mobility Email",
              ({ entity }: any) => entity?.account?.auctionMobilityEmail,
            ],
            [
              "accountAuctionMobilityId",
              "Account Auction Mobility ID",
              ({ entity }: any) => entity?.account?.auctionMobilityId,
            ],
            ["createdAt", "Created At"],
          ]}
          fetch={backend.lot.registrations(lotId)}
        />
      </div>
      <div className="box">
        <h2 className="subtitle">Events</h2>
        <DataTable
          columns={[
            ["type", "Type"],
            ["createdAt", "At"],
            [
              "userName",
              "User",
              ({ entity: event }: any) => event?.payload?.userId,
            ],
          ]}
          fetch={backend.lot.events(lotId)}
        />
      </div>
    </>
  );
};

export default LotPage;
