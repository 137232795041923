import * as React from "react";
import useReplaceQueryParams from "../../utils/useReplaceQueryParams";
import { Event } from "../../generated/types";
import useDataLoader from "../../utils/useDataLoader";
import Table from "../layouts/table";

const columns = [
  { title: "ID", dataIndex: "id", key: "id" },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "created_at",
    sorter: true,
  },
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "Payload",
    dataIndex: "payload",
    key: "payload",
    render: (payload: any) => JSON.stringify(payload),
  },
];

const dataLoaderParams = {
  path: "/events",
  queryParams: ["page", "order", "direction"],
};

const EventsPage = () => {
  const { data, meta } = useDataLoader<Event[]>(dataLoaderParams);

  const { push } = useReplaceQueryParams();
  const onChange = (_: any, _x: any, sorter: any) => {
    const { column, order } = sorter;

    push({
      order: column?.key || "",
      direction: order === "ascend" ? "asc" : order === "descend" ? "desc" : "",
    });
  };

  return (
    <>
      <Table
        onChange={onChange}
        dataSource={data}
        columns={columns}
        meta={meta}
        rowKey="id"
      />
    </>
  );
};

export default EventsPage;
