"use client";

import { Flex, Pagination, Space, Table as AntDTable } from "antd";
import useReplaceQueryParams from "../../utils/useReplaceQueryParams";
import { MetaResult } from "../../generated/types";
import React from "react";

type TableProps = {
  dataSource: any;
  columns: any[];
  meta?: MetaResult | null;
  rowKey: string;
  onChange?: (pagination: any, filters: any, sorter: any) => void;
};

const Table = (props: TableProps) => {
  const { push } = useReplaceQueryParams();
  const onChange = (page: number) => {
    push({ page });
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <AntDTable
        {...props}
        rowKey={(record) => record[props.rowKey]}
        pagination={false}
        className="mb-8"
      />
      {props.meta && (
        <Flex justify="end">
          <Pagination
            defaultCurrent={props.meta.page}
            total={props.meta.total_pages * props.meta.per_page}
            pageSize={props.meta.per_page}
            onChange={onChange}
            showSizeChanger={false}
          />
        </Flex>
      )}
    </Space>
  );
};

export default Table;
