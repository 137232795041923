import { useCallback, useEffect, useMemo, useState } from "react";
import * as backend from "../../../../Backend";
import { arrayMove } from "@dnd-kit/sortable";

const useComparablesSection = (
  lotId: string,
  toast: (toast: any) => void,
  setVimeoDeleteAction: (array: Array<any>) => void,
) => {
  const [saving, setSaving] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [comparables, setComparables] = useState<null | any[]>(null);
  const [showComparables, setShowComparables] = useState(false);
  const [showNewComparable, setShowNewComparable] = useState(false);
  const [editComprableId, setEditComparableId] = useState<string | null>(null);
  const [newComparable, setNewComparable] = useState<File | undefined>(
    undefined,
  );
  const [newComparableRow, setNewComparableRow] = useState<string>("1");
  const [newComparableDescription, setNewComparableDescription] = useState("");
  const [reorderComparables, setReorderComparables] = useState(false);
  const [comparablesPositions, setComparablesPositions] = useState<{
    [key: string]: number;
  }>({});
  const [newVimeoForComparableAt, setNewVimeoForComparableAt] = useState(-1);

  const row1Items = useMemo(
    () => comparables?.filter((c) => c.row === 1) || [],
    [comparables],
  );
  const row2Items = useMemo(
    () => comparables?.filter((c) => c.row === 2) || [],
    [comparables],
  );

  const loadComparables = useCallback(async () => {
    const comparablesData = await backend.lot.comparables(lotId);
    reorderList(comparablesData);
    setComparables(comparablesData);
    return comparablesData;
  }, [lotId]);

  const handleDragEnd = useCallback(
    async (event) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      // Check if the items is for row 1 or row 2
      const isRow1 = row1Items.some((item) => item.id === active.id);
      const items = isRow1 ? row1Items : row2Items;

      // Find the original and new positions of the dragged item within its row
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const updatedItems = arrayMove(items, oldIndex, newIndex);

      const newComparables = isRow1
        ? [...updatedItems, ...row2Items]
        : [...row1Items, ...updatedItems];

      setComparables(newComparables);
      const newPosition = newComparables.reduce((positions, item, index) => {
        positions[item.id] = index;
        return positions;
      }, {});
      setComparablesPositions(newPosition);

      try {
        await backend.lot.comparablePositions(lotId, { data: newPosition });
      } catch (error) {
        console.error("Error saving comparables positions:", error);
      }
    },
    [row1Items, row2Items, lotId],
  );

  const reorderList = (comparables: any[]) => {
    let j = 0;
    setComparablesPositions(
      comparables.reduce((obj: any, i: any) => {
        obj[i.id] = j;
        j++;
        return obj;
      }, {}),
    );
  };

  const uploadComparable = useCallback(async () => {
    if (!newComparable && !editComprableId) {
      return;
    }
    setSaving(true);

    const body = {
      description: newComparableDescription,
      row: newComparableRow,
      width: 1,
      height: 1,
    };

    if (editComprableId) {
      await backend.lot.updateComparable(
        lotId,
        editComprableId,
        newComparable,
        body,
      );
    } else {
      await backend.lot.addComparable(lotId, newComparable, body);
    }

    toast({ type: "good", message: "Image added" });
    setShowNewComparable(false);
    setNewComparable(undefined);
    setSaving(false);
    setNewComparableDescription("");
    setNewComparableRow("1");
    setEditComparableId(null);
    loadComparables();
  }, [
    editComprableId,
    lotId,
    newComparable,
    newComparableDescription,
    newComparableRow,
    toast,
    loadComparables,
  ]);

  const bulkUpload = useCallback(
    async (
      imageFiles: File[],
      setProgress?: (progress: number) => void,
      row?: number,
    ) => {
      if (!imageFiles.length) return;

      let completedUploads = 0;
      const totalImages = imageFiles.length;

      /*const maxPosition = comparables?.reduce(
        (max, item) => Math.max(max, item.position ?? 0),
        0,
      );*/

      await Promise.all(
        imageFiles.map(async (imageFile) => {
          const x = await backend.lot.addComparable(lotId, imageFile, {
            width: 0,
            height: 0,
            description: "",
            row: row ?? 1,
            //position: maxPosition + index + 1,
          });
          console.log(x);
          completedUploads += 1;

          if (setProgress) {
            const progress = Math.round((completedUploads / totalImages) * 100);
            setProgress(progress);
          }
        }),
      );

      /*for (const imageFile of imageFiles) {
        try {
          await backend.lot.addComparable(lotId, imageFile, {
            width: 0,
            height: 0,
            description: "",
            row: 1,
          });
          completedUploads += 1;

          if (setProgress) {
            const progress = Math.round((completedUploads / totalImages) * 100);
            setProgress(progress);
          }
        } catch (error) {
          console.error("Error uploading comparable:", error);
        }
      }*/

      toast({ type: "good", message: "Comparables added" });

      // Recargar imágenes y actualizar posiciones
      const updatedComparables = await loadComparables();
      /*if (updatedComparables) {
        const updateComparablesPositions = updatedComparables.reduce(
          (positions: any, item: any, index: number) => {
            positions[item.id] = index;
            return positions;
          },
          {} as { [key: string]: number },
        );
        setComparablesPositions(updateComparablesPositions);
      }*/
    },
    [lotId, loadComparables, toast],
  );

  const saveComparableOrdering = useCallback(async () => {
    try {
      console.log("Reordering comparables");
      await backend.lot.comparablePositions(lotId, {
        data: comparablesPositions,
      });
      setReorderComparables(false);
      await loadComparables();
      toast({ message: "Reorder finished", type: "success" });
    } catch (error) {
      console.error("Error ordering images:", error);
    }
  }, [lotId, loadComparables, comparablesPositions, toast]);

  const onDeleteComparable = useCallback(
    async (image: any) => {
      try {
        console.log("Deleting comparable:", image.id);
        await backend.lot.deleteComparable(lotId, image.id);
        loadComparables();
        toast({ message: "comparable deleted", type: "success" });
      } catch (error) {
        console.error("Error deleting comparable:", error);
      }
    },
    [lotId, loadComparables, toast],
  );

  const onAddComparable = () => {
    setNewComparableDescription("");
    setNewComparableRow("1");
    setShowNewComparable(true);
  };

  const onEditComparable = (comparable: any) => {
    setNewComparableDescription(comparable.description);
    setNewComparableRow(comparable.row);
    setEditComparableId(comparable.id);
    setShowNewComparable(true);
  };

  const deleteLotComparableVimeo = useCallback(
    async (lotComparable: any) => {
      try {
        console.log("Deleting comparable VIMEO:", lotComparable.id);
        await backend.lot.deleteLotComparableVimeo(lotId, lotComparable.id);
        setVimeoDeleteAction([null, null]);
        lotComparable.vimeoVideo = undefined;
        toast({ type: "good", message: "Vimeo video has been deleted" });
      } catch (error) {
        toast({ type: "bad", message: "Could not delete Vimeo video" });
        console.error(error);
      }
    },
    [lotId, toast, setVimeoDeleteAction],
  );

  const updateLotComparableVimeo = useCallback(
    async (vimeoId: string, width: number, height: number) => {
      if (!comparables) return;
      const lotComparable = comparables[newVimeoForComparableAt];
      try {
        console.log("Updating comparable vimeo:", lotComparable.id);
        const vimeoVideo: any = await backend.lot.updateLotComparableVimeo(
          lotId,
          lotComparable.id,
          vimeoId,
          width,
          height,
        );
        lotComparable.vimeoVideo = vimeoVideo.data.attributes.vimeoVideo;
        setComparables((prevComparables) => {
          if (!prevComparables) return prevComparables;
          const updatedComparables = [...prevComparables];
          updatedComparables[newVimeoForComparableAt] = lotComparable;
          return updatedComparables;
        });
        setNewVimeoForComparableAt(-1);
        toast({ type: "good", message: "Vimeo video has been updated" });
      } catch (error) {
        toast({ type: "bad", message: "Could not update the Vimeo video" });
        console.error(error);
      }
    },
    [lotId, toast, comparables, newVimeoForComparableAt],
  );

  useEffect(() => {
    loadComparables();
  }, [loadComparables]);

  return {
    comparables,
    setShowComparables,
    showComparables,
    reorderComparables,
    setReorderComparables,
    comparablesPositions,
    setComparablesPositions,
    deleteLotComparableVimeo,
    saveComparableOrdering,
    onAddComparable,
    onEditComparable,
    onDeleteComparable,
    showNewComparable,
    setShowNewComparable,
    setNewComparable,
    newComparableDescription,
    setNewComparableDescription,
    newComparableRow,
    setNewComparableRow,
    saving,
    uploadComparable,
    newVimeoForComparableAt,
    setNewVimeoForComparableAt,
    updateLotComparableVimeo,
    row1Items,
    row2Items,
    handleDragEnd,
    bulkUpload,
    showBulkModal,
    setShowBulkModal,
  };
};

export default useComparablesSection;
