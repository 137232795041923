import React, { useState } from "react";
import { Lot } from "../../../../generated/types";
import useLot from "../useLot";
import InlineEdit from "../../../InlineEdit";
import { formatDateTime } from "../../../../Util";
import AuctionDateInput from "./components/AuctionDateInput";

interface LotSectionProps {
  lot: Lot;
  loadLot: () => void;
}

const LotSection: React.FC<LotSectionProps> = ({ lot, loadLot }) => {
  const { updateLot } = useLot(lot.id);

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-half">
          {lot.masterImageUrl ? (
            <div>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={lot.masterImageUrl} alt="masterImage" />
              <div>Type: {lot.masterImageUrl.split(".")?.pop()}</div>
            </div>
          ) : (
            <div>
              <strong>No Image</strong>
            </div>
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Title</strong>
        </div>
        <div className="column">
          <InlineEdit
            name="title"
            initialValue={lot.title}
            onSubmit={updateLot}
            afterSubmit={loadLot}
          />
        </div>
        <div className="column">
          <strong>Auction Mobility ID</strong>
        </div>
        <div className="column">
          <InlineEdit
            name="auctionMobilityId"
            initialValue={lot.auctionMobilityId}
            onSubmit={updateLot}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Artist Name</strong>
        </div>
        <div className="column">
          <InlineEdit
            name="artistName"
            initialValue={lot.artistName}
            onSubmit={updateLot}
          />
        </div>
        <div className="column">
          <strong>Year of Creation</strong>
        </div>
        <div className="column">
          <InlineEdit
            name="yearOfCreation"
            initialValue={lot.yearOfCreation}
            onSubmit={updateLot}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Active?</strong>
        </div>
        <div className="column">{lot.active ? "Yes" : "No"}</div>
        <div className="column">
          <strong>Estimate</strong>
        </div>
        <div className="column">
          <InlineEdit
            name="estimate"
            initialValue={lot.estimate}
            onSubmit={updateLot}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Auction Starts At?</strong>
        </div>
        <div className="column is-three-quarters">
          <AuctionDateInput
            name="auctionStartsAt"
            initialValue={lot.auctionStartsAt as Date}
            onSubmit={updateLot}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Viewing Location</strong>
        </div>
        <div className="column is-three-quarters">
          <InlineEdit
            name="viewingLocation"
            initialValue={lot.viewingLocation}
            onSubmit={updateLot}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Detail</strong>
        </div>
        <div className="column is-three-quarters">
          <div className="is-linewrap">
            <InlineEdit
              name="detail"
              initialValue={lot.detail}
              inputType="textarea"
              onSubmit={updateLot}
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Condition Report</strong>
        </div>
        <div className="column is-three-quarters">
          <div className="is-linewrap">
            <InlineEdit
              name="conditionReport"
              initialValue={lot.conditionReport}
              inputType="textarea"
              onSubmit={updateLot}
            />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <strong>Hide Share link</strong>
        </div>
        <div className="column is-three-quarters">
          <InlineEdit
            name="hideShareLink"
            initialValue={lot.hideShareLink || false}
            inputType="checkbox"
            onSubmit={updateLot}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Hide Scheduling View</strong>
        </div>
        <div className="column is-three-quarters">
          <InlineEdit
            name="hideSchedulingView"
            initialValue={lot.hideSchedulingView || false}
            inputType="checkbox"
            onSubmit={updateLot}
          />
        </div>
      </div>
    </>
  );
};

export default LotSection;
