import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const MAX_FILES = 10;

interface FormModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onConfirm: (
    images: File[],
    setProgres: (progress: number) => void,
    row?: number,
  ) => void;
  rowsEnabled?: boolean;
}

const BulkUploadModal: React.FC<FormModalProps> = ({
  visible,
  setVisible,
  onConfirm,
  rowsEnabled = false,
}) => {
  const [images, setImages] = useState<File[]>([]);
  const [saving, setSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedRow, setSelectedRow] = useState<number | undefined>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setImages((prevImages) => {
      const newFiles = [...prevImages, ...acceptedFiles];
      return newFiles.slice(0, MAX_FILES);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
    maxFiles: MAX_FILES,
  });

  const handleConfirm = async () => {
    if (!images.length) return;
    setSaving(true);
    try {
      await onConfirm(images, setProgress, selectedRow);
      setImages([]);
      setVisible(false);
    } catch (error) {
      console.error("Error saving images:", error);
    } finally {
      setSaving(false);
      setProgress(0);
      setSelectedRow(undefined);
    }
  };

  const handleCancel = () => {
    setImages([]);
    setVisible(false);
    setProgress(0);
    setSelectedRow(undefined);
  };

  return (
    <div className={`modal ${visible ? "is-active" : ""}`}>
      <div className="modal-background" onClick={handleCancel} />
      <div className="modal-content">
        <div className="box">
          <div>Upload new images (png/jpg)</div>
          {rowsEnabled && (
            <div style={{ marginBottom: "10px" }}>
              <label htmlFor="">
                Row:
                <select
                  value={selectedRow}
                  defaultValue={"1"}
                  onChange={(e) => setSelectedRow(Number(e.target.value))}
                >
                  <option value="1">row 1</option>
                  <option value="2">row 2</option>
                </select>
              </label>
            </div>
          )}
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              border: "2px dashed #007BFF",
              padding: "50px",
              borderRadius: "8px",
              textAlign: "center",
              color: "#6c757d",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            <input {...getInputProps()} />
            <p>Drop images here, or click to select files</p>
            <em>{`(${MAX_FILES} files are the maximum number of files you can drop here)`}</em>
          </div>
          <div>
            {images.length > 0 && (
              <div>
                <strong>Files:</strong>
                <ul>
                  {images.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {saving ? (
            <div style={{ marginTop: "20px" }}>
              <progress
                className="progress is-small is-primary"
                max="100"
              ></progress>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p className="label">{progress}%</p>
              </div>
            </div>
          ) : (
            <div className="buttons is-right" style={{ marginTop: "20px" }}>
              <button className="button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="button is-primary" onClick={handleConfirm}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
      <button className="modal-close is-large" onClick={handleCancel} />
    </div>
  );
};

export default BulkUploadModal;
