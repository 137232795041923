import * as React from "react";
import { useEffect, useState } from "react";
import { Page } from "../Backend/Models/Page";
import { pipe } from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import { Column, DataTableRow, RowAction } from "./DataTable";

interface Entity {
  [key: string]: any;
}

interface Props<T extends Entity> {
  fetch: (page: number) => Promise<Page<T>>;
  columns: Column[];
  page: number;
  onPageChange: (page: number) => void;
  actions?: RowAction<T>[];
  forceReload: any;
}

export function DataTableControlled<T extends Entity>({
  forceReload,
  fetch,
  columns,
  actions = [],
  page,
  onPageChange,
}: Props<T>) {
  const [mbPage, setPage] = useState<O.Option<any>>(O.none);

  useEffect(() => {
    fetch(page).then((page) => {
      setPage(O.some(page));
    });
  }, [fetch, page, forceReload]);

  function changePage(page: number) {
    onPageChange(page);
  }

  return pipe(
    mbPage,
    O.fold(
      () => (
        <div>
          <progress className="progress is-small is-primary" max="100">
            15%
          </progress>
        </div>
      ),
      (results) => {
        return (
          <>
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  {columns.map(([_, label]) => (
                    <th key={label}>{label}</th>
                  ))}
                  {actions.map((action) => (
                    <th key={action.label} />
                  ))}
                </tr>
              </thead>
              <tbody>
                {results.data.length === 0 ? (
                  <tr>
                    <td>There is nothing here.</td>
                  </tr>
                ) : (
                  results.data.map((u: any) => (
                    <DataTableRow
                      key={u.id}
                      entity={u}
                      columns={columns}
                      actions={actions}
                    />
                  ))
                )}
              </tbody>
            </table>
            {results.data.length === 0 ? (
              <></>
            ) : (
              <nav
                className="pagination"
                role="navigation"
                aria-label="pagination"
              >
                <button
                  className="pagination-previous"
                  disabled={results.meta.page === 1}
                  onClick={() => changePage(page - 1)}
                >
                  Previous
                </button>
                <button
                  className="pagination-next"
                  disabled={results.meta.page === results.meta.total_pages}
                  onClick={() => changePage(page + 1)}
                >
                  Next page
                </button>
                <ul className="pagination-list">
                  <li>
                    <button
                      disabled={results.meta.page === 1}
                      className="pagination-link"
                      onClick={() => changePage(1)}
                    >
                      First
                    </button>
                  </li>
                  <li>
                    <button className="pagination-link is-current">
                      {results.meta.page} of {results.meta.total_pages}
                    </button>
                  </li>
                  <li>
                    <button
                      disabled={results.meta.page === results.meta.total_pages}
                      className="pagination-link"
                      onClick={() => changePage(results.meta.total_pages)}
                    >
                      Last
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </>
        );
      },
    ),
  );
}
