import React, { useEffect, useState } from "react";
import * as backend from "../Backend";

const settingsKeys = [
  "stopOnNavigate",
  "autoplayOnHome",
  "autoplayOnForeGround",
  "autoplayMode",
  "controlLinks",
  "showWebViewOn",
  "loadingTheme",
];

export const ParkingLotForm = ({ parkingLot, onClose }: any) => {
  const [form, setForm] = useState<any>({
    version: "1",
    taggedBody: "",
    settings: {},
  });
  const [error, setError] = useState("");

  const onChange = (key: string, value: any) => {
    if (settingsKeys.includes(key)) {
      setForm({
        ...form,
        settings: {
          ...form.settings,
          [key]: value,
        },
      });
      return;
    }

    setForm({
      ...form,
      [key]: value,
    });
  };

  useEffect(() => {
    if (parkingLot) {
      setForm({
        ...parkingLot,
        version: parkingLot.version ? parkingLot.version.toString() : "1",
      });
    }
  }, [parkingLot]);

  const onSubmit = async () => {
    try {
      if (form.id) {
        await backend.parkingLots.update(form.id, { parking: form });
      } else {
        await backend.parkingLots.create({ parking: form });
      }
      onClose(true);
    } catch (e) {
      setError((e as any).response?.data?.errors);
    }
  };

  console.log(form.settings);

  return (
    <div className="columns">
      <div className="column" />
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            <h3 className="title">Create Parking Lot</h3>
          </div>
        </div>

        {error && <div className="notification is-danger">{error}</div>}

        <div className="field">
          <label htmlFor="version" className="label">
            Version
          </label>
          <div className="control">
            <select
              name="version"
              id="version"
              onChange={(e) => onChange("version", e.target.value)}
            >
              <option value="1" selected={form.version.toString() === "1"}>
                1
              </option>
              <option value="2" selected={form.version.toString() === "2"}>
                2
              </option>
            </select>
          </div>
        </div>

        {form.version.toString() === "1" && (
          <>
            <div className="field">
              <label htmlFor="taggedBody" className="label">
                Tagged Body
              </label>
              <div className="control">
                <input
                  onChange={(e) => onChange("taggedBody", e.target.value)}
                  value={form.taggedBody}
                  placeholder="taggedBody"
                  id="taggedBody"
                  className="input"
                  type="text"
                  name="taggedBody"
                />
              </div>
            </div>
          </>
        )}

        {form.version.toString() === "2" && (
          <>
            <div className="field">
              <label htmlFor="url" className="label">
                URL
              </label>
              <div className="control">
                <input
                  onChange={(e) => onChange("url", e.target.value)}
                  value={form.url}
                  placeholder="url"
                  id="url"
                  className="input"
                  type="text"
                  name="url"
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="stopOnNavigate" className="checkbox">
                <input
                  onChange={(e) => onChange("stopOnNavigate", e.target.checked)}
                  id="stopOnNavigate"
                  name="stopOnNavigate"
                  type="checkbox"
                  checked={form.settings?.stopOnNavigate}
                />
                Stop On Navigate
              </label>
            </div>

            <div className="field">
              <label htmlFor="autoplayOnHome" className="checkbox">
                <input
                  onChange={(e) => onChange("autoplayOnHome", e.target.checked)}
                  id="autoplayOnHome"
                  name="autoplayOnHome"
                  type="checkbox"
                  checked={form.settings?.autoplayOnHome}
                />
                AutoPlay on Home
              </label>
            </div>

            <div className="field">
              <label htmlFor="autoplayOnForeGround" className="checkbox">
                <input
                  onChange={(e) =>
                    onChange("autoplayOnForeGround", e.target.checked)
                  }
                  id="autoplayOnForeGround"
                  name="autoplayOnForeGround"
                  type="checkbox"
                  checked={form.settings?.autoplayOnForeGround}
                />
                AutoPlay on ForeGround
              </label>
            </div>

            <div className="field">
              <label htmlFor="controlLinks" className="checkbox">
                <input
                  onChange={(e) => onChange("controlLinks", e.target.checked)}
                  id="controlLinks"
                  name="controlLinks"
                  type="checkbox"
                  checked={form.settings?.controlLinks}
                />
                Control Links (Open in native browser app)
              </label>
            </div>

            <div className="field">
              <label htmlFor="autoplayMode" className="checkbox">
                AutoPlay Mode
              </label>
              <div className="control">
                <select
                  name="autoplayMode"
                  id="autoplayMode"
                  onChange={(e) => onChange("autoplayMode", e.target.value)}
                >
                  <option
                    value="resume"
                    selected={form.settings?.autoplayMode === "resume"}
                  >
                    Resume
                  </option>
                  <option
                    value="restart"
                    selected={form.settings?.autoplayMode === "restart"}
                  >
                    Restart
                  </option>
                </select>
              </div>
            </div>

            <div className="field">
              <label htmlFor="showWebViewOn" className="label">
                Show webview on
              </label>
              <div className="control">
                <select
                  name="showWebViewOn"
                  id="showWebViewOn"
                  onChange={(e) => onChange("showWebViewOn", e.target.value)}
                >
                  <option
                    value="always"
                    selected={form.settings?.showWebViewOn === "always"}
                  >
                    Always (No loading indicator)
                  </option>
                  <option
                    value="on-load-start"
                    selected={form.settings?.showWebViewOn === "on-load-start"}
                  >
                    On page load start
                  </option>
                  <option
                    value="on-load-end"
                    selected={form.settings?.showWebViewOn === "on-load-end"}
                  >
                    On page load end
                  </option>
                </select>
              </div>
            </div>

            <div className="field">
              <label htmlFor="loadingTheme" className="label">
                Loading theme
              </label>
              <div className="control">
                <select
                  name="loadingTheme"
                  id="loadingTheme"
                  onChange={(e) => onChange("loadingTheme", e.target.value)}
                >
                  <option
                    value="light"
                    selected={form.settings?.loadingTheme === "light"}
                  >
                    Light
                  </option>
                  <option
                    value="dark"
                    selected={form.settings?.loadingTheme === "dark"}
                  >
                    Dark
                  </option>
                </select>
              </div>
            </div>
          </>
        )}

        <div className="buttons is-right">
          <button className="button is-danger is-light" onClick={onClose}>
            Cancel
          </button>
          <button
            onClick={onSubmit}
            className="button is-success"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
      <div className="column" />
    </div>
  );
};
