import React, { useEffect, useState } from "react";
import * as O from "fp-ts/lib/Option";
import { Column, DataTable } from "../../Components/DataTable";
import * as backend from "../../Backend";
import LotForm from "../../Components/LotForm";
import { Lot, LotsParking, LotsPast } from "../../generated/types";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../Components/ConfirmationModal";
import FormModal from "../../Components/FormModal";
import { useToast } from "../../providers/notification.provider";
import { Link, useNavigate } from "react-router-dom";

const LotsPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [parkingLot, setParkingLot] = useState<LotsParking | null>(null);
  const [forceReload, setForceReload] = useState<any>(Math.random());
  const [showNewLot, setShowNewLot] = useState(false);
  const [showNewParkingLot, setShowNewParkingLot] = useState(false);
  const [upcomingLots, setUpcomingLots] = useState<Lot[] | null>(null);
  const [pastLots, setPastLots] = useState<Array<LotsPast>>([]);
  const [pastLotToUpdate, setPastLotToUpdate] = useState<LotsPast | null>(null);
  const [pastLotToDelete, setPastLotToDelete] = useState<LotsPast | null>(null);
  const [reorderPastLots, setReorderPastLots] = useState(false);
  const [showImportLotModal, setShowImportLotModal] = useState(false);
  const [importLotJson, setImportLotJson] = useState<File | null | undefined>(
    null,
  );
  const [savingJsonLot, setSavingJsonLot] = useState(false);
  const [pastLotsPositions, setPastLotsPositions] = useState<{
    [key: string]: number | null;
  }>({});
  const [pastLotsPublished, setPastLotsPublished] = useState<{
    [key: string]: boolean;
  }>({});
  const [pastLotThumbnailToUpdate, setPastLotThumbnailToUpdate] =
    useState<LotsPast | null>(null);
  const [pastLotDetailImageToUpdate, setPastLotDetailImageToUpdate] =
    useState<LotsPast | null>(null);
  const [pastLotVideoToUpdate, setPastLotVideoToUpdate] =
    useState<LotsPast | null>(null);
  const {
    register: registerParkingLot,
    handleSubmit: handleParkingLotSubmit,
    formState: { errors: parkingLotErrors },
    reset: resetParkingLot,
  } = useForm();

  useEffect(() => {
    backend.lot.upcoming().then(setUpcomingLots);

    // TODO Luiyit: Test it
    backend.parkingLots
      .current()
      .then(setParkingLot)
      .catch(() => setParkingLot(null));

    getPastLots();
  }, []);

  const createParkingLot = (data: any) => {
    backend.lot
      .createParkingLot(data)
      .then(setParkingLot)
      .then(() => {
        resetParkingLot();
        setShowNewParkingLot(false);
      });
  };

  const updatePastLot = (
    pastLot: LotsPast,
    soldOn?: string,
    soldPrice?: string,
    published?: boolean,
  ) => {
    backend.pastLot
      .update(pastLot.id, {
        ...(soldOn && { soldOn }),
        ...(soldPrice && { soldPrice }),
        ...(published !== undefined && { published }),
      })
      .then((pastLot) => {
        setPastLotToUpdate(null);
        setPastLotsPublished({
          ...pastLotsPublished,
          [pastLot.id]: pastLot.published,
        });
        return getPastLots();
      });
  };

  const deletePastLot = (pastLot: LotsPast) => {
    backend.pastLot.delete(pastLot.id).then(() => {
      setPastLotToDelete(null);
      getPastLots();
    });
  };

  const getPastLots = () => {
    backend.pastLot.all().then((pastLots) => {
      setPastLots(pastLots);
      const { positions, publishedStatus } = pastLots.reduce(
        (result: any, pastLot) => {
          result.positions[pastLot.id] = pastLot.position;
          result.publishedStatus[pastLot.id] = pastLot.published;
          return result;
        },
        { positions: {}, publishedStatus: {} },
      );
      setPastLotsPositions(positions);
      setPastLotsPublished(publishedStatus);
    });
  };

  const onImportLot = () => {
    setShowImportLotModal(true);
  };

  const onSubmitLotJson = async () => {
    setSavingJsonLot(true);
    await backend.lot.import(importLotJson!);
    setSavingJsonLot(false);
    setImportLotJson(null);
    setShowImportLotModal(false);
    setForceReload(Math.random());
    toast({
      message: "Lot import started",
      type: "good",
    });
  };

  const updatePastLotsPositions = () => {
    if (
      Object.values(pastLotsPositions).some((position) => position === null)
    ) {
      toast({
        message: "All positions in the list of past lots must be set",
        type: "bad",
      });
      return;
    }
    backend.pastLot.updatePositions(pastLotsPositions).then(() => {
      getPastLots();
      setReorderPastLots(false);
    });
  };

  const updatePastLotThumbnail = async (
    pastLot: LotsPast,
    file: any,
    width: number,
    height: number,
  ) => {
    await backend.pastLot.updateThumbnail(pastLot.id, file.item(0), {
      width,
      height,
    });
    await getPastLots();
    setPastLotThumbnailToUpdate(null);
  };

  const updatePastLotVideo = async (
    pastLot: LotsPast,
    vimeoId: string,
    width: number,
    height: number,
  ) => {
    await backend.pastLot.updateVideo(pastLot.id, { vimeoId, width, height });
    await getPastLots();
    setPastLotVideoToUpdate(null);
  };

  const updatePastLotDetailImage = async (
    pastLot: LotsPast,
    file: any,
    width: number,
    height: number,
  ) => {
    await backend.pastLot.updateDetailImage(pastLot.id, file.item(0), {
      width,
      height,
    });
    await getPastLots();
    setPastLotDetailImageToUpdate(null);
  };

  const view = async (lot: Lot) => {
    navigate(`/lots/${lot.id}`);
    return O.none;
  };

  const columns: Column[] = [
    ["title", "Title"],
    ["artistName", "Artist"],
    ["auctionMobilityId", "Auction Mobility ID"],
    ["auctionStartsAt", "Auction Starts At"],
    ["createdAt", "Created At"],
    ["active", "Active?"],
    [
      "mixpanel",
      "Mixpanel?",
      ({ entity }: any) => (!!entity?.configuration?.mixpanable ? "✓" : ""),
    ],
    ["auctionLive", "Auction Live?"],
  ];

  const actions = [{ action: view, label: "View" }];

  const onCancelThumbnail = () => {
    pastLotThumbnailToUpdate !== null
      ? setPastLotThumbnailToUpdate(null)
      : setPastLotDetailImageToUpdate(null);
  };

  const onConfirmThumbnail = (file: any, width: any, height: any) => {
    if (pastLotThumbnailToUpdate) {
      updatePastLotThumbnail(pastLotThumbnailToUpdate!, file, +width, +height);
      return;
    }

    updatePastLotDetailImage(
      pastLotDetailImageToUpdate!,
      file,
      +width,
      +height,
    );
  };

  if (showNewLot) {
    return (
      <LotForm
        onSuccess={(l) => navigate(`/lots/${l.id}`)}
        onCancel={() => setShowNewLot(false)}
      />
    );
  }

  return (
    <>
      <ConfirmationModal
        bodyText={
          "Are you sure you want to remove this lot from the list of Past Lots?"
        }
        confirmText="Yes"
        cancelText="No"
        visible={pastLotToDelete !== null}
        onConfirm={() => deletePastLot(pastLotToDelete!)}
        onCancel={() => setPastLotToDelete(null)}
      />

      {showImportLotModal && (
        <div className={`modal ${showImportLotModal ? "is-active" : ""}`}>
          <div
            className="modal-background"
            onClick={() => setShowImportLotModal(false)}
          />
          <div className="modal-content">
            <div className="box">
              <div>Upload JSON File</div>
              <div>
                <input
                  type="file"
                  onChange={(e) => setImportLotJson(e.target.files?.[0])}
                />
              </div>
              {savingJsonLot ? (
                <div style={{ marginTop: "20px" }}>
                  <progress className="progress is-small is-primary" max="100">
                    40%
                  </progress>
                </div>
              ) : (
                <div className="buttons is-right">
                  <button
                    className="button is-primary"
                    onClick={onSubmitLotJson}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
          <button
            className="modal-close is-large"
            onClick={() => setShowImportLotModal(false)}
          />
        </div>
      )}

      <FormModal
        form={[
          {
            label: "Enter sold price",
            inputPlaceholder:
              (pastLotToUpdate && pastLotToUpdate.soldPrice) || undefined,
          },
          { label: "Enter date at when the lot was sold", inputType: "date" },
        ]}
        confirmText="Update"
        visible={pastLotToUpdate !== null}
        onCancel={() => setPastLotToUpdate(null)}
        onConfirm={(soldPrice, soldOn) =>
          updatePastLot(pastLotToUpdate!, soldOn, soldPrice)
        }
      />

      <div className={`modal ${showNewParkingLot ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => {
            setShowNewParkingLot(false);
            resetParkingLot();
          }}
        />
        <div className="modal-content">
          <div className="box">
            <form onSubmit={handleParkingLotSubmit(createParkingLot)}>
              <div className="field">
                <label htmlFor="taggedBody" className="label">
                  Body
                </label>
                <div className="control">
                  <textarea
                    className="input"
                    {...registerParkingLot("taggedBody", { required: true })}
                  />
                  {parkingLotErrors.taggedBody && (
                    <p className="help is-danger">Body required</p>
                  )}
                </div>
              </div>

              <div className="buttons is-right">
                <button className="button is-success" type="submit">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="modal-close is-large"
          onClick={() => {
            setShowNewParkingLot(false);
            resetParkingLot();
          }}
        />
      </div>

      <div className="columns is-vcentered">
        <div className="column">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li className="is-active">
                <span className="as_link as_link_padding">Lots</span>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column">
          <div className="buttons is-right">
            <button
              className="button is-primary"
              onClick={() => setShowNewLot(true)}
            >
              New Lot
            </button>
            <button className="button is-primary" onClick={onImportLot}>
              Import Lot
            </button>
          </div>
        </div>
      </div>

      <FormModal
        form={[
          {
            label:
              pastLotThumbnailToUpdate !== null ? "Thumbnail" : "Detail Image",
            inputType: "file",
            required: true,
          },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={
          pastLotThumbnailToUpdate !== null ||
          pastLotDetailImageToUpdate !== null
        }
        confirmText="Save"
        onCancel={onCancelThumbnail}
        onConfirm={onConfirmThumbnail}
      />

      <FormModal
        form={[
          { label: "Vimeo ID", inputType: "text", required: true },
          { label: "Width", inputType: "number", required: false },
          { label: "Height", inputType: "number", required: false },
        ]}
        visible={pastLotVideoToUpdate !== null}
        confirmText="Save"
        onCancel={() => setPastLotVideoToUpdate(null)}
        onConfirm={(videoId, width, height) =>
          updatePastLotVideo(pastLotVideoToUpdate!, videoId, +width, +height)
        }
      />

      {parkingLot ? (
        <div className="box" style={{ background: "#00d1b2" }}>
          <h2
            className="subtitle"
            style={{ color: "white", fontWeight: "bold" }}
          >
            Parking Lot Active
          </h2>
        </div>
      ) : null}

      <div className="box">
        <h2 className="subtitle">Upcoming Lots</h2>
        {upcomingLots ? (
          <div className="columns">
            <div className="column">
              <strong>Current</strong>
            </div>
            <div className="column">
              {upcomingLots[0] ? (
                <Link to={`/lots/${upcomingLots[0].id}`}>
                  {upcomingLots[0].title}
                </Link>
              ) : (
                "None"
              )}
            </div>
            <div className="column">
              <strong>Upcoming</strong>
            </div>
            <div className="column">
              {upcomingLots[1] ? (
                <Link to={`/lots/${upcomingLots[1].id}`}>
                  {upcomingLots[1].title}
                </Link>
              ) : (
                "None"
              )}
            </div>
          </div>
        ) : (
          <div>
            <progress className="progress is-small is-primary" max="100">
              15%
            </progress>
          </div>
        )}
      </div>

      <div className="box">
        <DataTable
          columns={columns}
          forceReload={forceReload}
          fetch={backend.lot.all}
          actions={actions}
        />
      </div>

      <div className="box">
        <h2 className="subtitle">Past Lots</h2>
        <div className="buttons is-right">
          {reorderPastLots ? (
            <>
              <button
                className="button is-danger is-light"
                onClick={() => setReorderPastLots(false)}
              >
                Cancel
              </button>
              <button
                className="button is-primary"
                onClick={() => updatePastLotsPositions()}
              >
                Save Ordering
              </button>
            </>
          ) : (
            <>
              <button
                className="button"
                onClick={() => setReorderPastLots(true)}
              >
                Reorder
              </button>
            </>
          )}
        </div>

        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <th style={{ width: "90px" }}>
                <abbr title="Position">Pos</abbr>
              </th>
              <th>
                <abbr title="Thumbnail">TIMG</abbr>
              </th>
              <th>
                <abbr title="Detail Image">DIMG</abbr>
              </th>
              <th>
                <abbr title="Video">VID</abbr>
              </th>
              <th>Title</th>
              <th>Artist</th>
              <th>Estimate</th>
              <th>Sold on</th>
              <th>Sold price</th>
              <th>Published?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pastLots.length > 0 &&
              pastLots.map((pastLot) => {
                return (
                  <tr key={pastLot.id}>
                    <td>
                      <button
                        className="delete is-medium"
                        onClick={() => setPastLotToDelete(pastLot)}
                      ></button>
                    </td>
                    <td>
                      {reorderPastLots ? (
                        <input
                          id={`pastlot-${pastLot.id}`}
                          className="input"
                          type="text"
                          placeholder={`${pastLot.position}`}
                          value={pastLotsPositions[pastLot.id] || ""}
                          onChange={(e) => {
                            if (!e.target.value) {
                              setPastLotsPositions({
                                ...pastLotsPositions,
                                [pastLot.id]: null,
                              });
                            } else {
                              const position = +e.target.value;
                              if (Number.isInteger(position) && position > 0) {
                                setPastLotsPositions({
                                  ...pastLotsPositions,
                                  [pastLot.id]: position,
                                });
                              }
                            }
                          }}
                        />
                      ) : (
                        pastLot.position
                      )}
                    </td>
                    <td>
                      {pastLot.thumbnailImageUrl ? (
                        <span
                          className="as_link"
                          onClick={() => setPastLotThumbnailToUpdate(pastLot)}
                        >
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img
                            alt=""
                            src={pastLot.thumbnailImageUrl}
                            style={{
                              width: "100px",
                              height: `${
                                (100 * pastLot.thumbnailImageHeight) /
                                pastLot.thumbnailImageWidth
                              }px`,
                            }}
                          />
                        </span>
                      ) : (
                        <button
                          className="is-light is-small"
                          onClick={() => setPastLotThumbnailToUpdate(pastLot)}
                        >
                          +
                        </button>
                      )}
                    </td>
                    <td>
                      {pastLot.detailImageUrl ? (
                        <span
                          className="as_link"
                          onClick={() => setPastLotDetailImageToUpdate(pastLot)}
                        >
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img
                            alt=""
                            src={pastLot.detailImageUrl}
                            style={{
                              width: "100px",
                              height: `${
                                (100 * pastLot.detailImageHeight) /
                                pastLot.detailImageWidth
                              }px`,
                            }}
                          />
                        </span>
                      ) : (
                        <button
                          className="is-light is-small"
                          onClick={() => setPastLotDetailImageToUpdate(pastLot)}
                        >
                          +
                        </button>
                      )}
                    </td>
                    <td>
                      {pastLot.vimeoVideo ? (
                        <button
                          className="is-info"
                          onClick={() => setPastLotVideoToUpdate(pastLot)}
                        >
                          #{pastLot.vimeoVideo.id}
                        </button>
                      ) : (
                        <button
                          className="is-light is-small"
                          onClick={() => setPastLotVideoToUpdate(pastLot)}
                        >
                          +
                        </button>
                      )}
                    </td>
                    <td>{pastLot.lot?.title}</td>
                    <td>{pastLot.lot?.artistName}</td>
                    <td>{pastLot.lot?.estimate}</td>
                    <td>{pastLot.soldOn}</td>
                    <td>{pastLot.soldPrice}</td>
                    <td>
                      <div className="field">
                        <input
                          id={`published-${pastLot.id}`}
                          type="checkbox"
                          name={`published-${pastLot.id}`}
                          className="switch"
                          checked={pastLotsPublished[pastLot.id]}
                          onChange={(e) => {
                            updatePastLot(
                              pastLot,
                              undefined,
                              undefined,
                              e.target.checked,
                            );
                          }}
                        />
                        <label htmlFor={`published-${pastLot.id}`}>
                          {/* NOTE: Label used by bulma-switch to properly render the switch object */}
                        </label>
                      </div>
                    </td>
                    <td>
                      <button
                        className="button is-warning is-light"
                        onClick={() => setPastLotToUpdate(pastLot)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LotsPage;
