import * as React from "react";
import { useEffect, useState } from "react";
import * as backend from "../../Backend";
import { Setting } from "../../generated/types";
import { useToast } from "../../providers/notification.provider";

const SettingsPage = () => {
  const toast = useToast();
  const [settings, setSettings] = useState<null | Array<Setting>>(null);
  const [values, setValues] = useState<any>({});

  useEffect(() => {
    backend.settings.get().then(setSettings);
  }, []);

  useEffect(() => {
    if (settings) {
      let newValues: any = {};
      settings.forEach((setting) => {
        newValues[setting.id] = JSON.stringify(setting.value);
      });
      setValues(newValues);
    }
  }, [settings]);

  const saveValue = (id: string) => async () => {
    try {
      await backend.settings.update(id, JSON.parse(values[id]));
      toast({ type: "good", message: "Saved" });
    } catch (e: any) {
      toast({ type: "bad", message: `Something went wrong: ${e.message}` });
    }
  };

  return (
    <>
      <div className="box">
        <h2 className="subtitle">Settings</h2>
        {settings ? (
          settings.map((setting) => (
            <div className="columns" key={setting.id}>
              <div className="column">{setting.name}</div>
              <div className="column">
                <textarea
                  value={values[setting.id] || ""}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [setting.id]: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="column">
                <button
                  className="button is-success is-light"
                  onClick={saveValue(setting.id)}
                >
                  Save
                </button>
              </div>
            </div>
          ))
        ) : (
          <progress className="progress is-small is-primary" max="100">
            15%
          </progress>
        )}
      </div>
    </>
  );
};

export default SettingsPage;
