import React from "react";
import { typeOptions } from "../..";

interface NewSectionProps {
  showNewSection: boolean;
  setShowNewSection: (show: boolean) => void;
  newSectionTitle: string;
  setNewSectionTitle: (title: string) => void;
  newSectionContent: string;
  setNewSectionContent: (content: string) => void;
  newSectionAudioUrl: string;
  setNewSectionAudioUrl: (audioUrl: string) => void;
  newSectionShowSeeMore: boolean;
  setNewSectionShowSeeMore: (show: boolean) => void;
  newSectionMedias: any[];
  setNewSectionMedias: (medias: any[]) => void;
  onUpdateNewSectionMedia: (index: number, key: string, value: any) => void;
  saveNewSection: () => void;
}

const NewSection: React.FC<NewSectionProps> = ({
  showNewSection,
  setShowNewSection,
  newSectionTitle,
  setNewSectionTitle,
  newSectionContent,
  setNewSectionContent,
  newSectionAudioUrl,
  setNewSectionAudioUrl,
  newSectionShowSeeMore,
  setNewSectionShowSeeMore,
  newSectionMedias,
  setNewSectionMedias,
  onUpdateNewSectionMedia,
  saveNewSection,
}) => {
  return (
    <div className={`modal ${showNewSection ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => setShowNewSection(false)}
      />
      <div className="modal-content">
        <div className="box">
          <h2 className="subtitle">Add new section</h2>
          <div className="field">
            <label className="label">Title:</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={newSectionTitle}
                onChange={(e) => setNewSectionTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Content:</label>
            <div className="control">
              <textarea
                className="textarea"
                value={newSectionContent}
                onChange={(e) => setNewSectionContent(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Audio url:</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={newSectionAudioUrl}
                onChange={(e) => setNewSectionAudioUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Show see more:</label>
            <input
              type="checkbox"
              checked={newSectionShowSeeMore}
              onChange={(e) => setNewSectionShowSeeMore(e.target.checked)}
            />
          </div>

          <h3>Medias</h3>

          {newSectionMedias.map((m: any, i: any) => (
            <div key={`m-${i}`}>
              <div className="control">
                <div className="select">
                  <select
                    defaultValue={"Media::Audio"}
                    onChange={(e) =>
                      onUpdateNewSectionMedia(i, "type", e.target.value)
                    }
                  >
                    {typeOptions.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="field">
                <label className="label">Title:</label>
                <div className="control">
                  <input
                    defaultValue={m.title}
                    className="input"
                    type="text"
                    onChange={(e) =>
                      onUpdateNewSectionMedia(i, "title", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Description:</label>
                <div className="control">
                  <input
                    defaultValue={m.description}
                    className="input"
                    type="text"
                    onChange={(e) =>
                      onUpdateNewSectionMedia(i, "description", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">URL:</label>
                <div className="control">
                  <input
                    defaultValue={m.url}
                    className="input"
                    type="text"
                    onChange={(e) =>
                      onUpdateNewSectionMedia(i, "url", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Thumbnail URL:</label>
                <div className="control">
                  <input
                    defaultValue={m.thumbnailUrl}
                    className="input"
                    type="text"
                    onChange={(e) =>
                      onUpdateNewSectionMedia(i, "thumbnailUrl", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">File:</label>
                <div className="control">
                  <input
                    type="file"
                    onChange={(e) =>
                      onUpdateNewSectionMedia(
                        i,
                        "fileFile",
                        e.target.files?.[0],
                      )
                    }
                  />
                </div>
              </div>
              {m.id && (
                <>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      onUpdateNewSectionMedia(
                        i,
                        "_destroy",
                        e.target.checked ? 1 : 0,
                      )
                    }
                  />
                  Delete
                </>
              )}
              <hr />
            </div>
          ))}

          <div className="buttons is-right">
            <button
              className="button is-primary"
              onClick={() =>
                setNewSectionMedias([
                  ...newSectionMedias,
                  { type: "Media::Audio" },
                ])
              }
            >
              Add new media
            </button>
          </div>

          <div className="buttons is-right">
            <button
              className="button is-primary"
              onClick={() => saveNewSection()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <button
        className="modal-close is-large"
        onClick={() => setShowNewSection(false)}
      />
    </div>
  );
};

export default NewSection;
