import * as React from "react";
import AuctionRoom from "../../Components/AuctionRoom";

const AuctionPage = () => {
  return (
    <>
      <AuctionRoom />
    </>
  );
};

export default AuctionPage;
