"use server";

import axios from "axios";
import decamelizeKeys from "decamelize-keys";
import { MetaResult } from "../generated/types";

const Deserializer = require("jsonapi-serializer").Deserializer;

type HttpOptions = {
  method?: "get" | "post" | "put" | "delete";
  headers?: {};
  skipDeserialization?: boolean;
};

const API_URL = process.env.REACT_APP_RAILS_API_ROOT || "http://localhost:5000";
const API_ID = process.env.REACT_APP_RAILS_API_ID;
const API_KEY = process.env.REACT_APP_RAILS_API_KEY;

const UNAUTHENTICATED = 401;
const UNAUTHORIZED = 403;

const http = <T>(
  url: string,
  body?: {},
  options?: HttpOptions,
): Promise<{ data: T; meta: MetaResult }> => {
  const method = options?.method || "get";
  const skipDeserialization = options?.skipDeserialization || false;
  const token = localStorage.getItem("mfaToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "X-API-ID": API_ID,
    "X-API-KEY": API_KEY,
    ...options?.headers,
  };

  const data = decamelizeKeys(body || {}, { deep: true });

  return Promise.resolve()
    .then(() => {
      return axios({
        baseURL: API_URL,
        url,
        method,
        headers,
        ...(method === "get" ? { params: data } : { data }),
      });
    })
    .then(async (response) => {
      const data = await new Deserializer({
        keyForAttribute: "camelCase",
      }).deserialize(response.data);

      return {
        data: data as T,
        meta: response.data?.meta,
      };
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === UNAUTHENTICATED ||
          error.response.status === UNAUTHORIZED)
      ) {
        // return redirect("/login");
      }

      throw error;
    });
};

export const get = <T>(url: string, params?: {}, options?: HttpOptions) => {
  return http<T>(url, params, { ...options, method: "get" });
};

export const post = <T>(url: string, body?: {}, options?: HttpOptions) => {
  return http<T>(url, body, { ...options, method: "post" });
};

export const put = <T>(url: string, body?: {}, options?: HttpOptions) => {
  return http<T>(url, body, { ...options, method: "put" });
};

export const del = <T>(url: string, options?: HttpOptions) => {
  return http<T>(url, {}, { ...options, method: "delete" });
};
