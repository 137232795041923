import { useCallback, useEffect, useState } from "react";
import * as backend from "../../../../Backend";
import { arrayMove } from "@dnd-kit/sortable";

const useImagesSection = (
  lotId: string,
  toast: (toast: any) => void,
  setVimeoDeleteAction: (array: Array<any>) => void,
) => {
  const [images, setImages] = useState<any[] | null>(null);
  const [showImages, setShowImages] = useState(false);
  const [imagePositions, setImagePositions] = useState<{
    [key: string]: number;
  }>({});
  const [reorderImages, setReorderImages] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [newVimeoForImageAt, setNewVimeoForImageAt] = useState(-1);

  const loadImages = useCallback(async () => {
    const imagesData = await backend.lot.images(lotId);
    reorderList(imagesData);
    setImages(imagesData);
    return imagesData;
  }, [lotId]);

  const handleDeleteImage = useCallback(
    async (image: any) => {
      try {
        console.log("Deleting image:", image.id);
        await backend.lot.deleteImage(lotId, image.id);

        const updatedImages = (images || [])
          .filter((img) => img.id !== image.id)
          .map((img, index) => ({ ...img, position: index }));

        setImages(updatedImages);

        const updatedImagePositions = updatedImages.reduce(
          (positions, img, index) => {
            positions[img.id] = index;
            return positions;
          },
          {} as { [key: string]: number },
        );
        setImagePositions(updatedImagePositions);
        await backend.lot.imagePositions(lotId, updatedImagePositions);
        toast({ message: "Image deleted", type: "success" });
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    },
    [images, lotId, toast],
  );

  const handleDragEnd = useCallback(
    async (event: any) => {
      const { active, over } = event;
      if (!over || !images) return;

      const activeIndex = images.findIndex((img) => img.id === active.id);
      const overIndex = images.findIndex((img) => img.id === over.id);

      if (activeIndex !== overIndex) {
        const reorderedImages = arrayMove(images, activeIndex, overIndex).map(
          (image, index) => ({
            ...image,
            position: index,
          }),
        );
        setImages(reorderedImages);

        const updatedImagePositions = reorderedImages.reduce(
          (positions, item, index) => {
            positions[item.id] = index;
            return positions;
          },
          {},
        );
        setImagePositions(updatedImagePositions);

        try {
          await backend.lot.imagePositions(lotId, updatedImagePositions);
        } catch (error) {
          console.error("Error saving image positions:", error);
        }
      }
    },
    [images, lotId],
  );

  const reorderList = (images: any[]) => {
    setImagePositions(
      images.reduce((obj: any, i: any, index: number) => {
        obj[i.id] = index;
        return obj;
      }, {}),
    );
  };

  const saveImageOrdering = useCallback(async () => {
    try {
      console.log("Reordering images");
      await backend.lot.imagePositions(lotId, imagePositions);
      setReorderImages(false);
      await loadImages();
      toast({ message: "Reorder finished", type: "success" });
    } catch (error) {
      console.error("Error ordering images:", error);
    }
  }, [lotId, loadImages, imagePositions, toast]);

  const deleteLotImageVimeo = useCallback(
    async (lotImage: any) => {
      try {
        await backend.lot.deleteLotImageVimeo(lotId, lotImage.id);
        setVimeoDeleteAction([null, null]);
        lotImage.vimeoVideo = undefined;
        toast({ type: "good", message: "Vimeo video has been deleted" });
      } catch (error) {
        toast({ type: "bad", message: "Could not delete Vimeo video" });
        console.error(error);
      }
    },
    [lotId, toast, setVimeoDeleteAction],
  );

  const handleDeleteVimeo = useCallback(
    (lotImage: any) => {
      setVimeoDeleteAction([deleteLotImageVimeo, lotImage]);
    },
    [deleteLotImageVimeo, setVimeoDeleteAction],
  );

  const uploadImages = useCallback(
    async (imageFiles: File[], setProgress?: (progress: number) => void) => {
      if (!imageFiles.length) return;

      let completedUploads = 0;
      const totalImages = imageFiles.length;

      await Promise.all(
        imageFiles.map(async (imageFile) => {
          await backend.lot.addImage(lotId, imageFile, { width: 0, height: 0 });
          completedUploads += 1;

          if (setProgress) {
            const progress = Math.round((completedUploads / totalImages) * 100);
            setProgress(progress);
          }
        }),
      );

      const updatedImages = await loadImages();
      if (updatedImages) {
        const reorderedImages = updatedImages
          .sort((a: any, b: any) => a.position - b.position)
          .map((image: File, index: number) => ({ ...image, position: index }));

        setImages(reorderedImages);
        const updatedImagePositions = reorderedImages.reduce(
          (positions: { [key: string]: number }, item: any, index: number) => {
            positions[item.id] = index;
            return positions;
          },
          {} as { [key: string]: number },
        );

        setImagePositions(updatedImagePositions);
        try {
          await backend.lot.imagePositions(lotId, updatedImagePositions);
        } catch (error) {
          console.error("Error saving image positions:", error);
        }
      }
      toast({ type: "good", message: "Images added" });
    },
    [lotId, loadImages, toast],
  );

  const updateLotImageVimeo = useCallback(
    async (vimeoId: string, width: number, height: number) => {
      if (!images) return;
      const lotImage = images[newVimeoForImageAt];
      try {
        const image: any = await backend.lot.updateLotImageVimeo(
          lotId,
          lotImage.id,
          vimeoId,
          width,
          height,
        );
        setNewVimeoForImageAt(-1);
        lotImage.vimeoVideo = image.vimeoVideo;
        toast({ type: "good", message: "Vimeo video has been updated" });
      } catch (error) {
        toast({ type: "bad", message: "Could not delete Vimeo video" });
        console.error(error);
      }
    },
    [lotId, toast, images, newVimeoForImageAt],
  );

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  return {
    images,
    showImages,
    imagePositions,
    setImagePositions,
    setShowImages,
    handleDragEnd,
    handleDeleteImage,
    reorderImages,
    setReorderImages,
    saveImageOrdering,
    deleteLotImageVimeo,
    handleDeleteVimeo,
    showNewModal,
    setShowNewModal,
    newVimeoForImageAt,
    setNewVimeoForImageAt,
    updateLotImageVimeo,
    uploadImages,
  };
};

export default useImagesSection;
