"use client";

import React from "react";
import LotPage from "../../../Components/Pages/LotPage";
import { useParams } from "react-router-dom";

const LotDetailsNextPage = () => {
  const { id } = useParams();

  return <LotPage lotId={id!} />;
};

export default LotDetailsNextPage;
