import * as React from "react";
import { useEffect, useState } from "react";
import { Page } from "../Backend/Models/Page";
import { pipe } from "fp-ts/lib/pipeable";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import { formatDateTime } from "../Util";

interface Entity {
  [key: string]: any;
}

interface Props<T extends Entity> {
  fetch: (page: number) => Promise<Page<T>>;
  columns: Column[];
  page?: number;
  onPageChange?: (page: number) => void;
  actions?: RowAction<T>[];
  forceReload?: any;
  rowKey?: (entity: any, index: number) => string;
}

export type Column = [any, string, any?];

export interface RowAction<T> {
  label: string;
  action: (entity: T) => Promise<Option<T>>;
  cssClass?: string;
  if?: (u: T) => boolean;
}

export function DataTable<T extends Entity>({
  fetch,
  rowKey = (e, _) => e.id,
  forceReload = 1,
  columns,
  actions = [],
  page = 1,
  onPageChange,
}: Props<T>) {
  const [curPage, setCurPage] = useState(page);
  const [mbPage, setPage] = useState<O.Option<any>>(O.none);

  useEffect(() => {
    fetch(curPage).then((page) => {
      setPage(O.some(page));
    });
  }, [curPage, forceReload]) // eslint-disable-line

  function changePage(page: number) {
    setCurPage(page);

    if (onPageChange) {
      onPageChange(page);
    }
  }

  return pipe(
    mbPage,
    O.fold(
      () => (
        <div>
          <progress className="progress is-small is-primary" max="100">
            15%
          </progress>
        </div>
      ),
      (page) => {
        return (
          <>
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  {columns.map(([_, label]) => (
                    <th key={label}>{label}</th>
                  ))}
                  {actions.map((action) => (
                    <th key={action.label} />
                  ))}
                </tr>
              </thead>
              <tbody>
                {page.data.length === 0 ? (
                  <tr>
                    <td>There is nothing here.</td>
                  </tr>
                ) : (
                  page.data.map((u: any, i: any) => {
                    return (
                      <DataTableRow
                        key={rowKey(u, i)}
                        entity={u}
                        columns={columns}
                        actions={actions}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
            {page.data.length > 0 && !page.meta?.total_pages ? (
              <></>
            ) : (
              <nav
                className="pagination"
                role="navigation"
                aria-label="pagination"
              >
                <button
                  className="pagination-previous"
                  disabled={page.meta?.page === 1}
                  onClick={() => changePage(curPage - 1)}
                >
                  Previous
                </button>
                <button
                  className="pagination-next"
                  disabled={page.meta?.page === page.meta?.total_pages}
                  onClick={() => changePage(curPage + 1)}
                >
                  Next page
                </button>
                <ul className="pagination-list">
                  <li>
                    <button
                      disabled={page.meta?.page === 1}
                      className="pagination-link"
                      onClick={() => changePage(1)}
                    >
                      First
                    </button>
                  </li>
                  <li>
                    <button className="pagination-link is-current">
                      {page.meta?.page} of {page.meta?.total_pages}
                    </button>
                  </li>
                  <li>
                    <button
                      disabled={page.meta?.page === page.meta?.total_pages}
                      className="pagination-link"
                      onClick={() => changePage(page.meta?.total_pages)}
                    >
                      Last
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </>
        );
      },
    ),
  );
}

export function DataTableRow<T extends Entity>({
  entity,
  columns,
  actions,
}: {
  entity: T;
  columns: Column[];
  actions: RowAction<T>[];
}) {
  const [curEntity, setEntity] = useState(entity);

  async function onActionClick(
    entity: T,
    action: (entity: T) => Promise<Option<T>>,
  ) {
    pipe(
      await action(entity),
      O.fold(
        () => {},
        (e) => setEntity(e),
      ),
    );
  }

  useEffect(() => {
    setEntity(entity);
  }, [entity]);

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  return (
    <tr>
      {columns.map(([key, _, render]) => {
        let value = curEntity[key];

        if (render && typeof render === "function") {
          value = render({ entity: curEntity });
        } else if (typeof value === "boolean") {
          value = value ? "✓" : "";
        } else if (typeof value === "number") {
        } else if (key === "createdAt" || key === "acceptedAt") {
          value = formatDateTime(value);
        } else if (key === "auctionStartsAt") {
          value = formatDateTime(value);
        } else if (value) {
          if (typeof value === "object") {
            value = JSON.stringify(value);
          } else if (value.startsWith("http")) {
            value = (
              <a href={value} target="_blank" rel="noreferrer">
                Link
              </a>
            );
          } else {
            value = <span title={value}>{truncateString(value, 50)}</span>;
          }
        }

        return <td key={key}>{value}</td>;
      })}
      {actions.map((action) => {
        return action.if == null || action.if(curEntity) ? (
          <td key={action.label}>
            <span
              onClick={() => onActionClick(curEntity, action.action)}
              className={`as_link ${action.cssClass ? action.cssClass : ""}`}
            >
              {action.label}
            </span>
          </td>
        ) : (
          <td key={action.label} />
        );
      })}
    </tr>
  );
}
