"use client";

import React from "react";
import AuctionPage from "../../Components/Pages/AuctionPage";

const AuctionRoomsNextPage = () => {
  return <AuctionPage />;
};

export default AuctionRoomsNextPage;
