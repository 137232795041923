import { useCallback, useEffect, useState } from "react";
import * as backend from "../../../../Backend";
import { arrayMove } from "@dnd-kit/sortable";
import { LotsSection as Section } from "../../../../generated/types";

const useSections = (lotId: string, toast: (toast: any) => void) => {
  const [sections, setSections] = useState<null | Section[]>(null);
  const [showSections, setShowSections] = useState(false);
  const [reorderSections, setReorderSections] = useState(false);
  const [sectionPositions, setSectionPositions] = useState<{
    [key: string]: number;
  }>({});

  const [showNewSection, setShowNewSection] = useState(false);
  const [editSectionId, setEditSectionId] = useState<string | null>(null);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newSectionContent, setNewSectionContent] = useState("");
  const [newSectionAudioUrl, setNewSectionAudioUrl] = useState("");
  const [newSectionShowSeeMore, setNewSectionShowSeeMore] = useState(false);
  const [newSectionMedias, setNewSectionMedias] = useState<any[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const loadSections = useCallback(async () => {
    const sectionsData = await backend.lot.sections(lotId);
    reorderList(sectionsData);
    setSections(sectionsData);
  }, [lotId]);

  const saveNewSection = useCallback(async () => {
    if (!newSectionTitle || !newSectionContent) return;
    const body = {
      title: newSectionTitle,
      content: newSectionContent,
      audio_url: newSectionAudioUrl,
      show_see_more: newSectionShowSeeMore,
      tagged_content: 1,
      mediasAttributes: newSectionMedias,
    };

    if (editSectionId) {
      await backend.lot.updateSection(lotId, editSectionId, body);
    } else {
      await backend.lot.createSection(lotId, body);
    }

    setNewSectionTitle("");
    setNewSectionContent("");
    setNewSectionMedias([]);
    setEditSectionId(null);
    setShowNewSection(false);
    toast({ type: "good", message: "Section added" });
    loadSections();
  }, [
    editSectionId,
    lotId,
    newSectionAudioUrl,
    newSectionContent,
    newSectionMedias,
    newSectionShowSeeMore,
    newSectionTitle,
    toast,
    loadSections,
  ]);

  const onAddSection = () => {
    setNewSectionTitle("");
    setNewSectionContent("");
    setNewSectionAudioUrl("");
    setNewSectionShowSeeMore(false);
    setEditSectionId(null);
    setShowNewSection(true);
  };

  const onEditSection = (section: any) => {
    setNewSectionTitle(section.title);
    setNewSectionContent(section.content);
    setNewSectionAudioUrl(section.audioUrl);
    setNewSectionShowSeeMore(section.showSeeMore);
    setNewSectionMedias(section.medias);
    setEditSectionId(section.id);
    setShowNewSection(true);
  };

  const onUpdateNewSectionMedia = (index: any, key: any, value: any) => {
    const medias = newSectionMedias.map((m: any, i: any) => {
      if (i === index) {
        m[key] = value;
        return m;
      }
      return m;
    });
    setNewSectionMedias(medias);
  };

  const onDeleteSection = useCallback(
    async (section: any) => {
      try {
        console.log("Deleting section:", section.id);
        await backend.lot.deleteSection(lotId, section.id);
        await loadSections();
        toast({ message: "section deleted", type: "success" });
      } catch (error) {
        console.error("Error deleting section:", error);
      }
    },
    [lotId, loadSections, toast],
  );

  const handleDragEnd = useCallback(
    async (event: any) => {
      if (!sections) return;
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = sections.findIndex(
          (section) => section.id === active.id,
        );
        const newIndex = sections.findIndex(
          (section) => section.id === over.id,
        );

        const updatedSections = arrayMove(sections, oldIndex, newIndex).map(
          (section, index) => ({
            ...section,
            position: index,
          }),
        );

        setSections(updatedSections);
        const updatedPositions = updatedSections.reduce(
          (positions, item, index) => {
            positions[item.id] = index;
            return positions;
          },
          {} as { [key: string]: number },
        );
        setSectionPositions(updatedPositions);
        setIsDragging(false);
        try {
          await backend.lot.sectionPositions(lotId, { data: updatedPositions });
        } catch (error) {
          console.error("Error saving section positions:", error);
        }
      }
    },
    [lotId, sections],
  );

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragCancel = () => {
    setIsDragging(false);
  };

  const reorderList = (sections: any[]) => {
    setSectionPositions(
      sections.reduce((obj: any, i: any) => {
        obj[i.id] = i.position;
        return obj;
      }, {}),
    );
  };

  const saveSectionOrdering = useCallback(async () => {
    try {
      console.log("Reordering sections");
      await backend.lot.sectionPositions(lotId, { data: sectionPositions });
      setReorderSections(false);
      await loadSections();
      toast({ message: "Reorder finished", type: "success" });
    } catch (error) {
      console.error("Error ordering images:", error);
    }
  }, [lotId, loadSections, sectionPositions, toast]);

  useEffect(() => {
    loadSections();
  }, [loadSections]);

  return {
    sections,
    showSections,
    sectionPositions,
    setSectionPositions,
    setShowSections,
    handleDragEnd,
    handleDragStart,
    handleDragCancel,
    reorderSections,
    setReorderSections,
    saveSectionOrdering,
    onEditSection,
    onDeleteSection,
    showNewSection,
    saveNewSection,
    onAddSection,
    onUpdateNewSectionMedia,
    setShowNewSection,
    newSectionTitle,
    setNewSectionTitle,
    newSectionContent,
    setNewSectionContent,
    newSectionAudioUrl,
    setNewSectionAudioUrl,
    newSectionShowSeeMore,
    setNewSectionShowSeeMore,
    newSectionMedias,
    setNewSectionMedias,
    isDragging,
  };
};

export default useSections;
