import React, { useEffect, useRef, useState } from "react";
import * as backend from "../Backend";
import { getDatabase, ref, onValue, off } from "firebase/database";
import { formatEpochTime } from "../Util";
import { DataTable } from "./DataTable";
import { User, Lot } from "../generated/types";
import { chunk } from "lodash";

const AuctionRoom = () => {
  const [bidders, setBidders] = useState<any[]>([]);
  const [spectators, setSpectators] = useState<any[]>([]);
  const [activeLot, setActiveLot] = useState<Lot | null>(null);
  const [auctionRegistrations, setAuctionRegistrations] = useState<
    any[] | null
  >(null);
  const cachedUsers = useRef<User[]>([]);

  useEffect(() => {
    backend.lot
      .upcoming()
      .then((upcomingLots) => setActiveLot(upcomingLots[0]));
  }, []);

  useEffect(() => {
    if (!activeLot) return;

    let refresh = setInterval(() => {
      backend.lot.registrationsUnpaginated(activeLot.id).then((data) => {
        setAuctionRegistrations(data);
      });
    }, 5000);

    return () => {
      clearInterval(refresh);
    };
  }, [activeLot]);

  useEffect(() => {
    if (!auctionRegistrations) return;

    const database = getDatabase();

    const presenceRef = ref(database, "auction-room-presence");
    const onValueChange = async (snapshot: any) => {
      let realtimeDatabaseUsers: any[] = [];

      snapshot.forEach((child: any) => {
        realtimeDatabaseUsers.push(child);
      });

      const newUsers = realtimeDatabaseUsers.filter(
        (u: any) => !cachedUsers.current.find((cu) => cu.id === u.key),
      );
      let users: User[] = [];

      if (newUsers.length) {
        const chunkedUsers = chunk(newUsers, 20);

        for (const userBlock of chunkedUsers) {
          users = users.concat(
            await backend.user.allById(userBlock.map((u: any) => u.key)),
          );
        }
      }
      const bidders: any[] = [];
      const spectators: any[] = [];

      realtimeDatabaseUsers.forEach((pu) => {
        const user =
          cachedUsers.current.find((e) => e.id === pu.key) ||
          users.find((e) => e.id === pu.key);
        const auctionRegistration = auctionRegistrations.find(
          (ar) =>
            user!.accounts.find((a: any) => a.primary && a.canBid)?.id ===
            ar.account?.id,
        );

        if (auctionRegistration) {
          bidders.push({
            joinedAt: pu.val(),
            user,
            auctionRegistration,
          });
        } else {
          spectators.push({
            joinedAt: pu.val(),
            user,
          });
        }
      });

      cachedUsers.current = cachedUsers.current.concat(users);

      setBidders(bidders);
      setSpectators(spectators);
    };

    onValue(presenceRef, onValueChange);

    return () => {
      off(presenceRef, "value", onValueChange);
    };
  }, [auctionRegistrations]);

  if (!activeLot) {
    return (
      <div>
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      </div>
    );
  }

  if (!activeLot.auctionLive) {
    return (
      <div>
        <h1>There is no active auction</h1>
      </div>
    );
  }

  if (!auctionRegistrations || !bidders || !spectators) {
    return (
      <div>
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      </div>
    );
  }

  return (
    <>
      <div className="box" style={{ backgroundColor: "lightblue" }}>
        <h2 className="subtitle">LIVE AUCTION ROOM: {activeLot.title}</h2>
        <h2 className="subtitle">Bidders</h2>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Joined At</th>
              <th>User Name</th>
              <th>Account Name</th>
              <th>Paddle</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {bidders.map((bidder) => (
              <tr key={bidder.user.id}>
                <td>{formatEpochTime(bidder.joinedAt)}</td>
                <td>
                  {bidder.user.firstName} {bidder.user.lastName}
                </td>
                <td>{bidder.auctionRegistration?.account?.name}</td>
                <td>{bidder.auctionRegistration?.paddle}</td>
                <td>{bidder.user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2 className="subtitle">Spectators</h2>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Joined At</th>
              <th>User Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {spectators.map((spectator) => (
              <tr key={spectator.user.id}>
                <td>{formatEpochTime(spectator.joinedAt)}</td>
                <td>
                  {spectator.user.firstName} {spectator.user.lastName}
                </td>
                <td>{spectator.user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="box">
        <h2 className="subtitle">Enter Bidding Room Events</h2>
        <DataTable
          columns={[
            ["type", "Type"],
            ["createdAt", "At"],
            [
              "userName",
              "User",
              ({ entity }: any) =>
                `${entity.payload?.user?.firstName} ${entity.payload?.user?.lastName}`,
            ],
            [
              "accountName",
              "Account",
              ({ entity }: any) => entity.payload?.account?.name,
            ],
          ]}
          fetch={backend.lot.biddingRoomEvents(activeLot.id)}
        />
      </div>
      <div className="box">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Auction Mobility ID</th>
              <th>Account Name</th>
              <th>Paddle</th>
            </tr>
          </thead>
          <tbody>
            {auctionRegistrations!.map((ar) => (
              <tr key={`${ar.id}-${ar.auctionMobilityId}`}>
                <td>{ar.auctionMobilityId}</td>
                <td>{ar.account?.name}</td>
                <td>{ar.paddle}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AuctionRoom;
