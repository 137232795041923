"use client";

import React from "react";
import UserPage from "../../../Components/Pages/UserPage";
import { useParams } from "react-router-dom";

const UserDetailsNextPage = () => {
  const { id } = useParams();

  return <UserPage userId={id!} />;
};

export default UserDetailsNextPage;
