import { useCallback, useEffect, useMemo, useState } from "react";
import { MetaResult } from "../generated/types";
import { get } from "./http";
import { useSearchParams } from "react-router-dom";

type UseDataLoaderType = {
  path: string;
  queryParams?: string[];
  params?: any;
};

const useDataLoader = <T>({ path, queryParams, params }: UseDataLoaderType) => {
  const [data, setData] = useState<T | null>(null);
  const [meta, setMeta] = useState<MetaResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const query = useMemo(() => {
    if (!queryParams) return {};

    return queryParams.reduce((acc: any, key: any) => {
      const value = searchParams.get(key);
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, [queryParams, searchParams]);

  const onLoadData = useCallback(async () => {
    setLoading(true);
    const { data, meta } = await get<T>(path, { ...query, ...params });
    setData(data);
    setMeta(meta);
    setLoading(false);
  }, [path, query, params]);

  useEffect(() => {
    (async () => onLoadData())();
  }, [onLoadData]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, meta, loading, onLoadData };
};

export default useDataLoader;
