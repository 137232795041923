import { useCallback } from "react";
import * as backend from "../../../Backend";
import { useToast } from "../../../providers/notification.provider";

const useLot = (lotId: string) => {
  const toast = useToast();
  const updateLot = useCallback(
    async (data: { name: string; value: string | boolean }) => {
      try {
        console.log("Updating lot:", data);
        await backend.lot.update(lotId, {
          [data.name]: data.value,
        });

        toast({ type: "good", message: "Lot updated" });
      } catch (error) {
        toast({ type: "bad", message: "Could not update lot" });
        console.error(error);
      }
    },
    [toast, lotId],
  );

  return {
    updateLot,
  };
};

export default useLot;
