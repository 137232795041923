import { formatDateTime } from "../../Util";
import * as React from "react";
import { User } from "../../generated/types";

type UserDetailsProps = {
  user: User;
  editUserInfo: boolean;
  setUserInfoUpdateRequest: (data: any) => void;
  userInfoUpdateRequest: any;
};

const UserDetails = ({
  user,
  editUserInfo,
  setUserInfoUpdateRequest,
  userInfoUpdateRequest,
}: UserDetailsProps) => {
  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <strong>First Name</strong>
        </div>
        <div className="column">
          {editUserInfo ? (
            <input
              className="input"
              type="text"
              placeholder={user.firstName}
              onChange={(e) =>
                setUserInfoUpdateRequest({
                  ...userInfoUpdateRequest,
                  firstName: e.target.value || undefined,
                })
              }
            />
          ) : (
            user.firstName
          )}
        </div>
        <div className="column">
          <strong>Email</strong>
        </div>
        <div className="column">
          {editUserInfo ? (
            <input
              className="input"
              type="email"
              placeholder={user.email}
              onChange={(e) =>
                setUserInfoUpdateRequest({
                  ...userInfoUpdateRequest,
                  email: e.target.value || undefined,
                })
              }
            />
          ) : (
            user.email
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Last Name</strong>
        </div>
        <div className="column">
          {editUserInfo ? (
            <input
              className="input"
              type="text"
              placeholder={user.lastName}
              onChange={(e) =>
                setUserInfoUpdateRequest({
                  ...userInfoUpdateRequest,
                  lastName: e.target.value || undefined,
                })
              }
            />
          ) : (
            user.lastName
          )}
        </div>
        <div className="column">
          <strong>Firebase ID</strong>
        </div>
        <div className="column">{user.firebaseId}</div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Status</strong>
        </div>
        <div className="column">{user?.status}</div>
        <div className="column">
          <strong>Locked At</strong>
        </div>
        <div className="column">{user?.lockedAt}</div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Admin?</strong>
        </div>
        <div className="column">{user.admin ? "Yes" : "No"}</div>
        <div className="column">
          <strong>Disabled?</strong>
        </div>
        <div className="column">{user.disabled ? "Yes" : "No"}</div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>Created At</strong>
        </div>
        <div className="column">{formatDateTime(user.createdAt)}</div>
        <div className="column">
          <strong>Modified At</strong>
        </div>
        <div className="column">{formatDateTime(user.createdAt)}</div>
      </div>
      <div className="columns">
        <div className="column">
          <strong>VIP</strong>
        </div>
        <div className="column">{user.vip ? "Yes" : "No"}</div>
        <div className="column"></div>
        <div className="column"></div>
      </div>
    </div>
  );
};

export default UserDetails;
