"use client";

import React from "react";
import SettingsPage from "../../Components/Pages/SettingsPage";

const SettingsNextPage = () => {
  return <SettingsPage />;
};

export default SettingsNextPage;
