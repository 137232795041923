import React, { useContext } from "react";
import fwLogo from "../../Style/fairwarning.png";
import { UserContext } from "../../providers/user_provider";
import { Link, useNavigate } from "react-router-dom";
import * as backend from "../../Backend";
import { signOut } from "../../firebase/auth/signInOut";

export const NavBar = () => {
  const { user, setUser } = useContext(UserContext);
  const url = window.location && window.location["href"];
  const shouldDisplayNavBar = !url.includes("/vip");
  const navigate = useNavigate();

  let bannerColor: string;
  if (process.env.REACT_APP_ENV_BANNER === "production") {
    bannerColor = "is-danger";
  } else if (process.env.REACT_APP_ENV_BANNER === "staging") {
    bannerColor = "is-warning";
  } else {
    bannerColor = "is-success";
  }

  const toggleMenu = () => {
    const menu = document.getElementById("navbarMenu");
    if (menu) {
      menu.classList.toggle("is-active");
    }
    const burger = document.querySelector(".navbar-burger");
    if (burger) {
      burger.classList.toggle("is-active");
    }
  };
  const closeMenu = () => {
    const menu = document.getElementById("navbarMenu");
    if (menu) {
      menu.classList.remove("is-active");
    }
    const burger = document.querySelector(".navbar-burger");
    if (burger) {
      burger.classList.remove("is-active");
    }
  };

  const onSignOut = async () => {
    await backend.user.signOut();
    localStorage.removeItem("mfaToken");
    await signOut();
    setUser(null);
    return navigate("/auth/sign-in");
  };

  return (
    <nav className="navbar has-shadow">
      <div className="navbar-brand">
        <div
          className="navbar-item is-uppercase has-text-weight-bold"
          style={{ background: "black", color: "white" }}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={fwLogo} alt="logo" />
        </div>
        <div
          className={`navbar-item is-uppercase notification is-light ${bannerColor}`}
        >
          {process.env.REACT_APP_ENV_BANNER || "development"} | v
          {process.env.REACT_APP_VERSION || "1.0.0"}
        </div>
        <button
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleMenu}
          style={{ background: "none", border: "none", cursor: "pointer" }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
      {user && shouldDisplayNavBar ? (
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/users" className="navbar-item" onClick={closeMenu}>
              Users
            </Link>
            <Link to="/lots" className="navbar-item" onClick={closeMenu}>
              Lots
            </Link>
            <Link
              to="/auction-rooms"
              className="navbar-item"
              onClick={closeMenu}
            >
              Auction Room
            </Link>
            <Link
              to="/invite-codes"
              className="navbar-item"
              onClick={closeMenu}
            >
              Invite Codes
            </Link>
            <Link
              to={"/referral-codes"}
              className="navbar-item"
              onClick={closeMenu}
            >
              Referral Codes
            </Link>
            <Link to="/features" className="navbar-item" onClick={closeMenu}>
              Features
            </Link>
            <Link
              to="/parking-lots"
              className="navbar-item"
              onClick={closeMenu}
            >
              Parking Lots
            </Link>
            <Link to="/events" className="navbar-item" onClick={closeMenu}>
              Events
            </Link>
            <Link
              to="/mass-notifications"
              className="navbar-item"
              onClick={closeMenu}
            >
              Mass Notifications
            </Link>
            <Link
              to="auction-rooms-alerts"
              className="navbar-item"
              onClick={closeMenu}
            >
              Auction Alerts
            </Link>
          </div>
          <div className="navbar-end">
            <span
              className="navbar-item"
              style={{ cursor: "pointer" }}
              onClick={onSignOut}
            >
              Sign Out
            </span>
          </div>
        </div>
      ) : null}
    </nav>
  );
};
