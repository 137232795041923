import { createSearchParams, useSearchParams } from "react-router-dom";

const useReplaceQueryParams = () => {
  const [_, setSearchParams] = useSearchParams();

  const push = (obj: any) => {
    setSearchParams(createSearchParams(obj).toString());
  };

  return {
    push,
  };
};

export default useReplaceQueryParams;
